<template>
  <div class="layout">
    <div class="query-bar">
      <div>
        <div class="title">
          <img style="width: 64px; height: 64px;margin-top: 6px;" :src="logo" />
          <div>{{ platformName }}</div>
        </div>
      </div>
      <div class="query-box">
        <a-dropdown :trigger="['click']" class="queryDropdown">
          <span class="ant-dropdown-link" @click="(e) => e.preventDefault()">
            {{ querySelect }}&nbsp;&nbsp;<a-icon type="caret-down" />
          </span>
          <a-menu slot="overlay" @click="queryClick">
            <template v-for="o in queryDrops">
              <a-menu-item :key="o.name">
                <a href="javascript:;">{{ o.name }}</a>
              </a-menu-item>
            </template>
          </a-menu>
        </a-dropdown>
        <a-divider style="height: 25px; width: 2px" type="vertical" />
        <a-input
          v-model="searchVal"
          class="queryInput"
          placeholder="搜索您要的信息"
          @blur="searchBlur"
        />
        <div class="queryButton" @click="globalSearch">搜索</div>
      </div>
      <!-- <div class="qr-code">
        <img :src="qrcode" style="width: 100px; height: 100px" />
        <div>扫一扫</div>
        <div>进入手机版</div>
      </div> -->
    </div>

    <div class="menuContainer">
      <a-menu
        class="menuStyle"
        :default-selected-keys="['/home']"
        :selectedKeys="[onRoutes]"
        mode="horizontal"
        :subMenuCloseDelay="0"
        :style="{
          boxShadow: '0px 1px 3px 0px rgb(0 0 0 / 12%)',
          backgroundColor: '#ffffff',
          margin: '0 167px 0 164px;'
        }"
      >
        <template v-for="item in menus">
          <template v-if="!item.submenus">
            <a-menu-item
              :style="menuStyle(menus.length)"
              :key="item.url"
              @click="menuClick"
            >
              <span>{{ item.name }}</span>
            </a-menu-item>
          </template>
          <template v-else>
            <a-sub-menu
              :style="menuStyle(menus.length)"
              :key="item.url"
              :title="item.name"
              @titleClick="titleClick"
            >
              <a-menu-item :key="item.url" @click="menuClick">
                <span>{{ item.name }}</span>
              </a-menu-item>
            </a-sub-menu>
          </template>
        </template>
      </a-menu>
    </div>

    <div class="content">
      <!-- 微应用挂载节点 -->
      <div id="container" :style="containerStyle">
        <div v-if="microAppLoading" class="spin-contain">
          <a-spin size="large"> </a-spin>
        </div>
      </div>
      <router-view v-if="!microAppsActive" />
    </div>
  </div>
</template>
<script>
let container

import { registerMicroApps, start } from "@ice/stark"
import { setBasename } from "@ice/stark-app"
import { mapGetters, mapMutations } from "vuex"
import store from "@/store"

const url = "https://zjfw.tzszyygs.com/"
const njSources = {
  ABcDvTbB20: [
    `${url}/tech/app.js`,
    `${url}/tech/js/home.js`,
    `${url}/tech/js/activity.js`,
    `${url}/tech/js/activityDetail.js`,
    `${url}/tech/js/expert.js`,
    `${url}/tech/js/expertDetail.js`,
    `${url}/tech/js/course.js`,
    `${url}/tech/js/courseDetail.js`,
    `${url}/tech/js/courseChapter.js`,
    `${url}/tech/js/live.js`,
    `${url}/tech/js/liveDetail.js`,
    `${url}/tech/js/expertStudioHome.js`,
    `${url}/tech/js/vendors~liveDetail.js`,
    `${url}/tech/js/vendors~courseDetail~expertStudioHome.js`,

    `${url}/tech/js/article.js`,
    `${url}/tech/js/articleDetail.js`,
    `${url}/tech/js/help.js`,
    `${url}/tech/js/HelpAdd.js`,
    `${url}/tech/js/helpDetail.js`,
    `${url}/tech/js/myArticles.js`,
    `${url}/tech/js/myExperts.js`,
    `${url}/tech/js/myCourses.js`,
    `${url}/tech/js/myMsgs.js`,
    `${url}/tech/css/app.css`,
    `${url}/tech/css/home.css`,
    `${url}/tech/css/activity.css`,
    `${url}/tech/css/activityDetail.css`,
    `${url}/tech/css/expert.css`,
    `${url}/tech/css/expertDetail.css`,
    `${url}/tech/css/course.css`,
    `${url}/tech/css/courseDetail.css`,
    `${url}/tech/css/courseChapter.css`,
    `${url}/tech/css/live.css`,
    `${url}/tech/css/expertStudioHome.css`,
    // `${url}/tech/css/vendors~liveDetail.css`,
    `${url}/tech/css/liveDetail.css`,
    `${url}/tech/css/article.css`,
    `${url}/tech/css/articleDetail.css`,
    `${url}/tech/css/help.css`,
    `${url}/tech/css/HelpAdd.css`,
    `${url}/tech/css/helpDetail.css`,
    `${url}/tech/css/myArticles.css`,
    `${url}/tech/css/myExperts.css`,
    `${url}/tech/css/myCourses.css`,
    `${url}/tech/css/myMsgs.css`
  ],
  ABcDvTbB30: [
    `${url}/tech/app.js`,
    `${url}/tech/js/home.js`,
    `${url}/tech/js/expertStudio.js`,
    `${url}/tech/js/expertStudioArticle.js`,
    `${url}/tech/js/ExpertStudioArticleData.js`,
    `${url}/tech/js/expertStudioCourse.js`,
    `${url}/tech/js/ExpertStudioCourseData.js`,
    `${url}/tech/js/expertStudioHome.js`,
    `${url}/tech/js/expertStudioLive.js`,
    `${url}/tech/js/ExpertStudioLiveDetails.js`,
    `${url}/tech/js/ExpertStudioSetting.js`,
    `${url}/tech/js/vendors~courseDetail~expertStudioHome.js`,
    `${url}/tech/js/live.js`,
    `${url}/tech/js/liveDetail.js`,
    `${url}/tech/js/vendors~liveDetail.js`,
    `${url}/tech/js/activity.js`,
    `${url}/tech/js/activityDetail.js`,
    `${url}/tech/js/expert.js`,
    `${url}/tech/js/expertDetail.js`,
    `${url}/tech/js/course.js`,
    `${url}/tech/js/courseDetail.js`,
    `${url}/tech/js/courseChapter.js`,
    `${url}/tech/js/article.js`,
    `${url}/tech/js/articleDetail.js`,
    `${url}/tech/js/help.js`,
    `${url}/tech/js/HelpAdd.js`,
    `${url}/tech/js/helpDetail.js`,
    `${url}/tech/js/myArticles.js`,
    `${url}/tech/js/myExperts.js`,
    `${url}/tech/js/myCourses.js`,
    `${url}/tech/js/myMsgs.js`,

    `${url}/tech/css/app.css`,
    `${url}/tech/css/home.css`,
    `${url}/tech/css/expertStudio.css`,
    `${url}/tech/css/expertStudioArticle.css`,
    `${url}/tech/css/ExpertStudioArticleData.css`,
    `${url}/tech/css/expertStudioCourse.css`,
    `${url}/tech/css/ExpertStudioCourseData.css`,
    `${url}/tech/css/expertStudioHome.css`,
    `${url}/tech/css/expertStudioLive.css`,
    `${url}/tech/css/ExpertStudioLiveDetails.css`,
    `${url}/tech/css/ExpertStudioSetting.css`,

    `${url}/tech/css/activity.css`,
    `${url}/tech/css/activityDetail.css`,
    `${url}/tech/css/expert.css`,
    `${url}/tech/css/expertDetail.css`,
    `${url}/tech/css/course.css`,
    `${url}/tech/css/courseDetail.css`,
    `${url}/tech/css/courseChapter.css`,
    `${url}/tech/css/article.css`,
    `${url}/tech/css/articleDetail.css`,
    `${url}/tech/css/live.css`,
    `${url}/tech/css/liveDetail.css`,
    `${url}/tech/css/help.css`,
    `${url}/tech/css/HelpAdd.css`,
    `${url}/tech/css/helpDetail.css`,
    `${url}/tech/css/myArticles.css`,
    `${url}/tech/css/myExperts.css`,
    `${url}/tech/css/myCourses.css`,
    `${url}/tech/css/myMsgs.css`
  ]
}

export default {
  components: {},
  data() {
    return {
      microAppsActive: false,
      platformName: this.__SYSTEM_APP_NAME,
      searchPath: "", // 搜索栏选择栏目路径
      searchValue: "",
      logo: 'http://bzcf.oss-cn-hangzhou.aliyuncs.com/portal/images/c0b57afa90eca3865550b525abde571.jpg',
      qrcode: require("@/assets/img/gh_79e71c45d614_430.jpg"),
      // 个人中心-农技学堂菜单
      // njPaths: [
      //   "/myNjkt/myExperts",
      //   "/myNjkt/myCourses",
      //   "/myNjkt/myArticles",
      //   "/myNjkt/myMsgs",
      // ],
      // 个人中心-农技学堂菜单
      njPaths: [
        "/myNjkt/myExperts",
        "/myNjkt/myCourses",
        "/myNjkt/myArticles",
        "/myNjkt/myMsgs",
        "/myNjkt/expertStudio",
        "/myNjkt/expertStudio/course",
        "/myNjkt/expertStudio/article"
      ],
      resources: [], // 静态资源文件
      queryDrops: [
        {
          name: "供求大厅",
          path: "/npyx/index"
        },
        // {
        //   name: "新闻资讯",
        //   path: "/news"
        // }
      ],
      querySelect: "请选择", // 搜索栏选中项
      searchVal: "" // 搜索关键字
    }
  },
  computed: {
    ...mapGetters(["menus"]),
    ...mapGetters(["isLogin"]),
    ...mapGetters(["userInfo"]),
    ...mapGetters(["microAppLoading"]),
    value() {
      return this.$route.path
    },
    menuStyle() {
      return (len) => {
        const width = `${parseFloat(100 / len)}%`
        return `width: ${width}`
      }
    },
    containerStyle() {
      let regx = /^\/njkt|\/jgfw*/g
      return regx.test(this.$route.path) ? { height: "100%" } : { height: 0 }
    },
    onRoutes() {
      const route = this.$route
      const { path } = route
      const arr = path.split("/")
      if (path.includes("/njkt")) {
        return "/njkt/"
      }
      if (arr.length > 1) {
        return `/${arr[1]}`
      }
      return path
    }
  },
  created() {
    console.log("layout created")
  },
  activated() {
    console.log("layout activated")
  },
  mounted() {
    console.log("layout mounted")

    const userType = this.userInfo.userType || "ABcDvTbB20"
    container = document.getElementById("container")
    // this.getBundleUrl().then(() => {
    registerMicroApps([
      {
        name: "njkt",
        activePath: `${process.env.BASE_URL}njkt/`,
        title: this.__SYSTEM_APP_NAME,
        sandbox: false,
        url: njSources[userType],
        container
      }
    ])

    start({
      prefetch: true,
      // 微应用 route 变化时的回调
      onRouteChange: (_, pathname) => {
        console.log("onRouteChange", _, pathname)
        let path = ""
        if (_.includes("http")) {
          path = pathname
        } else {
          path = _
        }

        // 个人中心-农技学堂-我关注的专家、课程、文章： 点击详情 => 跳转至首页-农技学堂
        if (
          path.includes("myNjkt") &&
          !this.njPaths.includes(path) &&
          !path.startsWith("/myNjkt/expertStudio")
        ) {
          path = path.replace("myNjkt", "njkt")
          setTimeout(() => {
            this.$router.push(path).catch(() => {}) // 处理微应用间跳转无法触发 Vue Router 响应
          })
        }
      },
      // 微应用开始被激活的回调
      onActiveApps: (activeApps) => {
        console.log("onActiveApps", activeApps)
        this.microAppsActive = (activeApps || []).length
        console.log("microAppsActive", this.microAppsActive)
      },
      // 微应用渲染前的回调
      onAppEnter: (appConfig) => {
        const { activePath } = appConfig
        setBasename(activePath)
      },
      // 微应用开始加载的回调
      onLoadingApp: (appConfig) => {
        console.log("onLoadingApp", appConfig)
        store.commit("setMicroAppLoading")
      },
      // 微应用结束加载的回调
      onFinishLoading: (appConfig) => {
        console.log("onFinishLoading", appConfig)
        store.commit("setMicroAppLoading")
      }
    })
    // })
  },
  methods: {
    ...mapMutations(["setSearchTitle"]),
    // 获取静态资源地址
    async getBundleUrl() {
      const res = await this.$axios.staticResource("njkt")
      console.log("staticResource", res)
      if (res.data.body && res.data.body.resource.length > 0) {
        this.resources = [...res.data.body.resource]
      }
    },
    menuClick({ key }) {
      if (key === "/njkt/" && !this.isLogin) {
        this.$message.warning("请先登录再浏览农技学堂哦！")
        setTimeout(() => {
          window.location.href = "/login"
        }, 1000)
        return
      }
      if (key.includes("http")) {
        // 菜单url是外链，打开新窗口
        window.open(key)
      } else {
        // if(key === "/njkt/"){
        //   if(this.userInfo.userType === 'ABcDvTbB30'){
        //     key = '/njkt/expertStudio'
        //   }
        // }
        this.$router.push({
          path: key
        })
      }
    },
    titleClick({ key, e }) {
      console.log("titleClick", key, e)
      this.$router.push({
        path: key
      })
    },
    // 搜索栏目点击
    queryClick({ key }) {
      this.querySelect = key
      this.searchPath = this.queryDrops.find((o) => o.name === key).path
    },
    searchBlur() {
      this.setSearchTitle({
        title: this.searchVal,
        type: this.querySelect
      })
    },
    globalSearch() {
      if (this.querySelect === "请选择") {
        this.$message.info("请选择您要搜索的模块！")
        return
      }
      this.setSearchTitle({
        title: this.searchVal,
        type: this.querySelect
      })
      // if (!this.searchVal) {
      //   this.$message.info('请输入您要的信息！')
      //   return
      // }
      //   const mainPath = this.searchPath.split("/")[1];
      if (!this.$route.path.includes(this.searchPath)) {
        this.$router.push(`${this.searchPath}`)
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import url("../../styles/pages/layout.less");
::v-deep .ant-menu-item {
  .ant-menu-submenu-title {
    transition: unset;
  }
  .ant-menu-submenu {
    border-bottom: unset !important;
  }
  .ant-menu-submenu-selected {
    border-bottom: unset !important;
  }
}
.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  top: 0;
  line-height: 46px;
  border-bottom: unset !important;
}
</style>
