<template>
  <div class="loginpageclass">
    <div class="newcardclass">
      <div class="cardgroundclass">
        <img src="@/assets/img/login_left_back.png" />
      </div>
      <div class="logininputcardclass">
        <div class="logininputtopcardclass">
          <div>
            <img style="width: 34px;height: 34px;" :src="logo" />
            <span>数字农业统一门户</span>
          </div>
          <span
            style="width: 104px;height: 3px;background: #20BF8E;border-radius: 2px;"
            >&nbsp;</span
          >
        </div>

        <div class="logininputbodycardclass">
          <div class="frombodycardclass">
            <div class="frombodycarddivclass">
              <a-form-model ref="ruleForm" :model="form" :rules="rules">
                <a-form-model-item ref="userName" prop="userName">
                  <a-input v-model="form.userName" placeholder="请输入手机号">
                    <img
                      slot="prefix"
                      :src="phoneImg"
                      style="width: 18px; height: 18px"
                    />
                  </a-input>
                </a-form-model-item>
                <template v-if="form.loginType === 'portal_password'">
                  <a-form-model-item ref="password" prop="password">
                    <a-input-password
                      v-model="form.password"
                      placeholder="请输入密码"
                    >
                      <img
                        slot="prefix"
                        :src="passImg"
                        style="width: 18px; height: 18px"
                      />
                    </a-input-password>
                  </a-form-model-item>
                </template>
                  <a-form-model-item
                    labelAlign="left"
                    required
                    prop="validateCode"
                  >
                    <div class="validated">
                      <div>
                        <a-input
                          v-model="form.validateCode"
                          @keydown.enter="onSubmit"
                          placeholder="请输入图形验证码"
                        >
                          <img
                            slot="prefix"
                            :src="guardImg"
                            style="width: 18px; height: 18px"
                          />
                          <!-- <a-icon slot="prefix" theme="filled" type="check-circle" style="font-size: 18px;" :style="{ color: form.validateCode.length === 4 ? '#20BF8E' : '' }"/> -->
                        </a-input>
                      </div>
                      <div>
                        <img
                          style="
                          width: 114px;
                          height: 32px;
                          margin-top: -10px;
                          cursor: pointer;
                        "
                          :src="`data:image/png;base64,` + codeImg"
                          alt=""
                          @click="getCode"
                        />
                      </div>
                    </div>
                  </a-form-model-item>
                <template v-if="form.loginType === 'portal_sms'">
                  <a-form-model-item ref="code" prop="code">
                    <a-input
                      v-model.trim="form.code"
                      style="width: 100%"
                      placeholder="请输入短信验证码"
                    >
                      <img
                        slot="prefix"
                        :src="guardImg"
                        style="width: 18px; height: 18px"
                      />
                      <div slot="suffix">
                        <a-divider type="vertical" />
                        <a-button
                          type="primary"
                          class="verificateBtn"
                          :disabled="countDownDisabled"
                          ghost
                          @click="getValidate"
                        >
                          {{ countDownMsg }}
                        </a-button>
                      </div>
                    </a-input>
                  </a-form-model-item>
                </template>
              </a-form-model>
            </div>
          </div>
          <div class="fromsubmitcardclass">
            <div class="fromsubmitcardclass">
              <div>
                <a-button
                  type="primary"
                  :loading="loading"
                  size="small"
                  style="font-size:18px; width: 100%;height: 50px;"
                  @click="onSubmit"
                >
                  登录
                </a-button>
              </div>
              <div class="tip">
                <a-radio
                  defaultValue="true"
                  :checked="radioChecked"
                  @click="rdClick"
                ></a-radio>
                <span>登录即代表您同意</span>
                <span
                  style="text-decoration: underline;color: #20BF8E;"
                  @click="look"
                  >平台服务协议</span
                >
              </div>
              <div class="regist">
                <span @click="toggleType">{{ bottomMsg }}</span>
                <span style="margin-left: 60px;" @click="goRegist"
                  >免费注册</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie"
import store from "@/store"
import { mapGetters } from "vuex"
let timer,
  time = 60

export default {
  data() {
    return {
      loading: false,
      phoneRegx: /^(0|86|17951)?(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|14[57])[0-9]{8}$/,
      codeImg: "",
      radioCheck: false,
      form: {
        loginType: 'portal_password',
        code: "",
        userName: "",
        password: "",
        validateId: "",
        validateCode: ""
      },
      rules: {
        userName: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { min: 3, max: 12, message: "手机号码11位", trigger: "blur" }
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 6, max: 12, message: "密码长度6～12位", trigger: "blur" }
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
        validateCode: [
          { required: true, message: "请输入图形验证码", trigger: "blur" }
        ]
      },
      countDownMsg: "获取验证码",
      countDownDisabled: false,
      passImg: require("@/assets/img/lock.png"),
      phoneImg: require("@/assets/img/phoneIcon.png"),
      guardImg: require("@/assets/img/guard.png"),
      bottomMsg: "短信登录",
      radioChecked: true,
      logo: 'https://berno-static-resources.oss-cn-shanghai.aliyuncs.com/static-bzcf-dev/logo-bzcf-dev.png'
    }
  },
  watch: {},
  computed: {
    ...mapGetters(["menus"]),
    ...mapGetters(["userInfo"])
  },
  mounted() {
    this.getCode()
  },
  destroyed() {
    clearInterval(timer)
  },
  methods: {
    look() {
      this.$router.push("/greements")
    },
    rdClick() {
      this.radioChecked = !this.radioChecked
    },
    toggleType() {
      this.form.loginType = this.form.loginType === 'portal_password' ? 'portal_sms' : 'portal_password'
      if (this.form.loginType === 'portal_sms') {
        this.bottomMsg = "密码登录"
        this.form.code = ""
      }
      if (this.form.loginType === 'portal_password') {
        this.bottomMsg = "短信登录"
        this.form.password = ""
        this.form.validateCode = ""
      }
    },
    // 获取图形验证码
    getCode() {
      this.$axios.getValidate().then((res) => {
        if (res.data.code === "0") {
          this.codeImg = res.data.body.validateBase64Str
          this.form.validateId = res.data.body.validateId
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 获取短信验证码
    getValidate() {
      if (!this.phoneRegx.test(this.form.userName) || this.form.loginType !== 'portal_sms') {
        return
      }
      const params = {
        body: {
          phone: this.form.userName,
          validateCode: this.form.validateCode,
          validateId: this.form.validateId
        }
      }
      timer = setInterval(this.countDown, 1000)
      this.$axios.loginSendCode(params).then((res) => {
        if (res.data.code === "0") {
          this.$message.success("发送成功")
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    countDown() {
      if (time === 0) {
        this.countDownMsg = "获取验证码"
        this.countDownDisabled = false
        time = 60
        clearInterval(timer)
      } else {
        this.countDownMsg = `重新发送(${time})`
        this.countDownDisabled = true
        time--
      }
    },
    encode(str) {
      // 对字符串进行编码
      var encode = encodeURI(str)
      // 对编码的字符串转化base64
      var base64 = btoa(encode)
      return base64
    },
    // 设置用户信息
    setUserInfo(data) {
      console.log("userInfo", data)
      const {
        accessToken,
        id,
        name,
        nickName,
        userName,
        phone,
        sex,
        lastLoginTime,
        isEnterpriseCertification,
        isPersonalCertification,
        roles
      } = data
      Cookies.set("accessToken", accessToken)
      const farmer = roles && roles.length ? roles.find((o) => o.name === "农技课堂-农户") : {}
      const expert = roles && roles.length ? roles.find((o) => o.name === "农技课堂-专家") : {}
      const userType = expert ? expert.code : farmer ? farmer.code : ""
      const userInfo = {
        id,
        name,
        nickName,
        userName,
        phone,
        sex,
        userType: userType || "ABcDvTbB20",
        lastLoginTime,
        isEnterpriseCertification,
        isPersonalCertification
      }
      sessionStorage.setItem("userInfo", JSON.stringify(userInfo))
      store.commit("setUserInfo", userInfo)
      store.commit("setRoles", roles || [])

      const menus = [...this.menus]
      menus.forEach((ele) => {
        if (ele.name === "农技学堂") {
          // if (this.userInfo.userType === 'ABcDvTbB30') {
          //   ele.url = '/njkt/expertStudio'
          // } else {
          //   ele.url = '/njkt/'
          // }
          ele.url = "/njkt/"
        }
      })
      store.commit("setMenus", menus)
    },
    // 登录
    async login() {
      /**
       * loginSrc 登录来源 0:PC 1:webapp 2:App 3:微信 4:IOS （根据实际需求后自定义）
       * password 密码
       * userName 账号/用户名
       * validateId 验证码ID
       * validateCode 验证码
       */
      try {
        const param = {
          body: { ...this.form }
        }
        const res = await this.$axios.userLogin(param)
        if (res.data.code === "0") {
          this.setUserInfo(res.data.body)
          this.$router.push({ path: "/" })
          this.form.code = ""
        } else {
          this.$message.error(res.data.msg)
          this.getCode()
        }
      } catch (e) {
        !e && this.$message.error("登录超时！")
        this.getCode()
      }
    },
    // 登录
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (!this.radioChecked) {
            this.$message.warn("请勾选平台服务协议！")
            return
          } else {
            this.loading = true
            this.login().then(() => {
              this.loading = false
            })
          }
        } else {
          this.$message.warning("请填写必填项！")
          return false
        }
      })
    },
    // 跳转注册页
    goRegist() {
      this.$router.push({ path: "/regist" })
    }
  }
}
</script>
<style lang="less" scoped>
.loginpageclass {
  height: 100%;
  // background: aqua;
  background-image: url("~@/assets/img/login_back.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  .rowgroudclass {
    height: 100%;
    .centerleftCardclass {
      height: 200px;
      background: red;
      .centerleftCarddivclass {
        height: 100%;
        display: flex;
        flex-direction: column;
        .centerleftCarddivtopclass {
          height: 70%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .centerleftCarddivbottomclass {
          height: 30%;
        }
      }
    }
    .centerRightCardclass {
      height: 300px;
      background: blue;
    }
  }
  .newcardclass {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    .cardgroundclass {
      height: 484px;
      min-width: 310px;
      max-width: 310px;
    }
    .cardgroundclass > img {
      width: 100%;
      height: 100%;
    }
    .logininputcardclass {
      width: 446px;
      height: 484px;
      background: white;
      // position: absolute;
      // right: 0px;
      // top: -22%;
      border-radius: 5px;
      display: flex;

      flex-direction: column;
      .logininputtopcardclass {
        padding: 0 60px;
        display: flex;
        justify-content: center;
        height: 30%;
        flex-direction: column;
        align-items: center;
        font-size: 26px;
        font-weight: 600;
        color: @primary-color;
        line-height: 37px;
      }
      .logininputbodycardclass {
        padding: 0 60px;
        .frombodycardclass {
          width: 100%;
          display: flex;
          justify-content: center;
          .frombodycarddivclass {
            width: 100%;
            /deep/ .ant-form-item-control-wrapper {
              border-bottom: 1px solid #979797;
            }
            /deep/ .ant-input {
              border: none;
              padding-left: 41px;
            }
            .typeStyle {
              cursor: pointer;
              text-align: right;
              font-size: 14px;
              font-weight: 400;
              color: @primary-color;
              line-height: 20px;
            }
            .verificateBtn {
              padding: 0;
              font-size: 14px;
              font-weight: 400;
              color: @primary-color;
              line-height: 20px;
            }
          }
        }
        .fromsubmitcardclass {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center;
          .tip {
            margin-top: 10px;
            font-size: 12px;
            font-weight: 400;
            color: #9d9d9d;
            line-height: 17px;
          }
          .regist {
            cursor: pointer;
            text-align: center;
            margin-top: 23px;
            font-size: 14px;
            font-weight: 400;
            color: @primary-color;
            line-height: 20px;
          }
        }
      }
    }
  }
  .validated {
    display: flex;
    justify-content: space-between;
  }
}
</style>
