<template>
  <div class="merchant">
    <a-form-model label-align="left">
      <div class="module">
        <SectionLine title="企业简介" />
        <div v-if="formData.id" class="con">
          <a-row :gutter="24">
            <a-col :span="8">
              <a-form-model-item label="企业名称："> {{ formData.name }} </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="企业地址："> {{ (formData['provinceName'] ? formData['provinceName'] : '') +
                        (formData['cityName'] ? formData['cityName'] : '') +
                        (formData['areaName'] ? formData['areaName'] : '') +
                        (formData['address'] ? formData['address'] : '') }} </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="负责人："> {{ formData.contacts }} </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <a-form-model-item label="经营范围："> {{ formData.businessScope }} </a-form-model-item>
            </a-col>
          </a-row>
        </div>
      </div>
      <div class="module">
        <SectionLine title="企业详情" />
        <div v-if="formData.introduction" class="con" v-html="formData.introduction" />
      </div>
      <div class="module">
        <SectionLine title="企业证书" />
        <div v-if="formData.certificates && formData.certificates.length" class="con">
          <img v-for="(item, index) in formData.certificates" :key="index" :src="item.cardImg | ossImg(130, 180)" @click="handlePictureCardPreview(item.cardImg)">
        </div>
      </div>
      <div class="module">
        <SectionLine title="企业视频" />
        <div v-if="formData.videoUrl" class="con">
          <div class="video" @click="showMerchantVideo(formData.videoUrl)">
            <img src="@/assets/img/companyVideoUrl.png">
          </div>
        </div>
      </div>
    </a-form-model>
    <a-modal v-model="showModal" width="30%" :destroy-on-close="true" :footer="null">
      <img width="100%" :src="modalImageUrl">
    </a-modal>
    <a-modal
      v-model="showVideoModal"
      width="50%"
      :destroy-on-close="true"
      :footer="null"
    >
      <companyVideoUrl :modal-trace-data="modalTraceData" />
    </a-modal>
  </div>
</template>
<script>
import companyVideoUrl from './companyVideoUrl'
export default {
  components: { companyVideoUrl },
  props: {
    formData: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data () {
    return {
      showModal: false,
      showVideoModal: false,
      modalTraceData: undefined,
      modalImageUrl: ''
    }
  },
  watch: {
    formData: {
      handler(val) {
        if (val && val.introduction) {
          val.introduction = val.replace(/<img/g, '<img style="max-width: 100%;"')
        }
      },
      deep: true
    }
  },
  created() {
    this.formData.introduction = this.formData.introduction ? this.formData.introduction.replace(/<img/g, '<img style="max-width: 100%;"') : ''
  },
  methods: {
    handlePictureCardPreview(imgUrl) {
      this.showModal = true
      this.modalImageUrl = this.$utils.ossPath.ossImg(imgUrl, 520, 720)
    },
    showMerchantVideo(url) {
      this.showVideoModal = true
      this.modalTraceData = url
    }
  }
}
</script>
<style lang="less" scoped>
.merchant {
  /deep/ .ant-form-item {
      margin-bottom: 0;
      .ant-form-item-label {
        width: 70px;
        float: left;
      }
      .ant-form-item-control-wrapper {
        width: calc(100% - 70px);
        float: left;
      }
    }
  .module {
    .con {
      padding: 5px 0;
      img {
        cursor: pointer;
      }
      img + img {
        margin-left: 16px;
      }
      .video {
        cursor: pointer;
        width: 273px;
        height: 177px;
        background-color: #e0f6ef;
        text-align: center;
        margin-top: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 150px;
        }
      }
    }
  }
}
</style>