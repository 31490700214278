import { render, staticRenderFns } from "./agTech.vue?vue&type=template&id=c63c8af0&scoped=true"
import script from "./agTech.vue?vue&type=script&lang=js"
export * from "./agTech.vue?vue&type=script&lang=js"
import style0 from "./agTech.vue?vue&type=style&index=0&id=c63c8af0&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c63c8af0",
  null
  
)

export default component.exports