import { GET, POST, ApiUrl } from '@/config/index'

// 热门
const queryHot = GET(`${ApiUrl}/web/process/api/backapp/process/web/supply/action/queryHot`)
// 需求 热门
const queryHotDemand = GET(`${ApiUrl}/web/process/api/backapp/process/web/demand/action/queryReMen`)

// 查询品类
const queryCategory = GET(`${ApiUrl}/web/process/api/backapp/process/web/category/action/queryCategory`)
// 查询品种
const queryVarietyById = id => {
    return GET(`${ApiUrl}/web/process/api/backapp/process/web/category/action/queryByParentId/${id}`)()
}
// 供应大厅列表查询
const querySupplyHall = POST(`${ApiUrl}/web/process/api/backapp/process/web/supply/action/querySupplyHall`)
// 供应详情查询
const getSupplyHallById = id => {
    return GET(`${ApiUrl}/web/process/api/backapp/process/web/supply/action/getSupplyHallById/${id}`)()
}
// 评价查询
const getEvaluate = POST(`${ApiUrl}/web/process/api/backapp/process/web/supply/action/getEvaluate`)
// 评价数量查询 评价
const queryEvaluateCount = id => {
    return GET(`${ApiUrl}/web/process/api/backapp/process/web/supply/action/queryEvaluateCount/${id}`)()
}
// 评价数量查询 other
const getOthers = id => {
    return GET(`${ApiUrl}/web/process/api/backapp/process/web/supply/action/getOthers/${id}`)()
}

// 需求大厅列表查询
const queryDemandHall = POST(`${ApiUrl}/web/process/api/backapp/process/web/demand/action/queryByPage`)

// 需求详情查询
const queryDemandDetail = POST(`${ApiUrl}/web/process/api/backapp/process/web/demand/action/detail`)

// 我的需求-查询、需求大厅
const demandGetOther = POST(`${ApiUrl}/web/process/api/backapp/process/web/demand/action/queryByPage`)

// 需求详情 收藏
const demandAddCollect = POST(`${ApiUrl}/web/process/api/backapp/process/web/favorites/`)

// 需求详情 取消收藏
const demandUnCollect = POST(`${ApiUrl}/web/process/api/backapp/process/web/favorites/action/deleteByCondition`)

// 门户首页-供应大厅
const queryHomeSupply = POST(`${ApiUrl}/web/process/api/backapp/process/web/supply/action/queryHomeSupply/`)

const queryHomeDemand = POST(`${ApiUrl}/web/process/api/backapp/process/web/demand/action/queryList`)

// 下单
const placeAnOrder = POST(`${ApiUrl}/web/process/api/backapp/process/web/order/`)

// 交易提醒
const platformInfo = POST(`${ApiUrl}/web/process/api/backapp/process/web/platformInfo/action/detail`)

export default {
    queryHot,
    queryHotDemand,
    queryCategory,
    queryVarietyById,
    querySupplyHall,
    queryDemandHall,
    getSupplyHallById,
    getEvaluate,
    queryEvaluateCount,
    getOthers,
    queryDemandDetail,
    demandGetOther,
    demandAddCollect,
    demandUnCollect,
    placeAnOrder,
    queryHomeSupply,
    queryHomeDemand,
    platformInfo
}
