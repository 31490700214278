import { POST, GET, ApiUrl } from '@/config/index'
// 企业列表
const merchantList = POST(`${ApiUrl}/web/source/api/backapp/sourcetrace/enterprise/action/noLoginPage`)
// 企业详情
const merchantDetail = id => GET(`${ApiUrl}/web/source/api/backapp/sourcetrace/enterprise/action/noLoginGetById/${id}`)()
// 企业溯源列表
const merchantSuYuanList = POST(`${ApiUrl}/web/source/api/backapp/sourcetrace/batchManagements/action/noLoginQueryByPage`)

// 商品-数据字典
const selectOption = types => GET(`${ApiUrl}/api/backapp/system/dict/action/getByTypes/${types}`)()

// 商品-数据字典
const getTraceInfoByGoodsId = id => GET(`${ApiUrl}/web/source/api/backapp/sourcetrace/batchManagements/action/noLoginPreview/${id}`)()
// 统计
const getHomePage = () => GET(`${ApiUrl}/web/source/api/backapp/sourcetrace/goodsCumulates/action/noLoginGetHome`)()
// 商品扫码柱状图分析
const histogramAnalysis = POST(`${ApiUrl}/web/source/api/backapp/sourcetrace/goodsCumulates/action/noLoginGetStaticalBarInfo`)
export default {
  merchantList: merchantList,
  merchantDetail: merchantDetail,
  merchantSuYuanList: merchantSuYuanList,
  selectOption: selectOption,
  getTraceInfoByGoodsId: getTraceInfoByGoodsId,
  histogramAnalysis: histogramAnalysis,
  getHomePage: getHomePage
}
