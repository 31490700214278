import { POST, GET, OutUrl } from '@/config/index'

// 农技专家 获取分页列表
const expertList = POST(`${OutUrl}/web/technology/api/backapp/technology/system/open/action/expert/queryByPage/`)
// 农技专家 获取详情
const expertDetail = GET(`${OutUrl}/web/technology/api/backapp/technology/system/open/action/expert/detailPage`)

// 农技课程 获取分页列表
const courseList = POST(`${OutUrl}/web/technology/api/backapp/technology/system/open/action/course/queryByPage/`)
// 农技课程 获取详情
const courseDetail = GET(`${OutUrl}/web/technology/api/backapp/technology/system/open/action/course/detailPage`)

// 农技文章 获取分页列表
const articleList = POST(`${OutUrl}/web/technology/api/backapp/technology/system/open/action/article/queryByPage/`)
// 农技文章 获取详情
const articleDetail = GET(`${OutUrl}/web/technology/api/backapp/technology/system/open/action/article/detailPage`)

// 根据pageUrl获取静态
const staticResource = (path) => {
    return GET(`${OutUrl}/web/technology/api/backapp/technology/system/open/action/staticResource/${path}`)()
}

export default {
    expertList,
    expertDetail,
    courseList,
    courseDetail,
    articleList,
    articleDetail,
    staticResource
}
