/**
 * ApiUrl 后台接口域名
 * ossUrl oss文件域名
 */

export default {
  dev: {
    ApiUrl: "/10004", // 10004端口请求
    OutUrl: "/10016", // 10016端口请求
    syUrl: "http://qy.szprod.iot-farm.cn:8888", // 嵌入溯源页面url
    ossUrl: "https://bzcf.oss-cn-hangzhou.aliyuncs.com/", //'http://123.57.67.20:10001'
    key: "DKIBZ-FOXWU-PYJVZ-43SE3-MPRRQ-VBB6U"
  },
  test: {
    ApiUrl: "/gateway",
    OutUrl: "https://cf.government-taizhou.iberno.cn//gateway",
    syUrl: "https://qy.sz.iot-farm.cn", // 嵌入溯源页面url
    ossUrl: "https://bzcf.oss-cn-hangzhou.aliyuncs.com/",
    key: "DKIBZ-FOXWU-PYJVZ-43SE3-MPRRQ-VBB6U"
  },
  pro: {
    ApiUrl: "/gateway",
    OutUrl: "/gateway",
    ossUrl: "https://bzcf.oss-cn-hangzhou.aliyuncs.com/",
    syUrl: "http://qy.szprod.iot-farm.cn:8888", // 嵌入溯源页面url
    key: "DKIBZ-FOXWU-PYJVZ-43SE3-MPRRQ-VBB6U"
  }
}
