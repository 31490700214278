import { POST, ApiUrl } from '@/config/index'

// 合作伙伴/友情链接/首页标签/banner
const homeQueryList = POST(`${ApiUrl}/web/portal/api/backapp/operate/portal/baseType/queryList`)

// 企业风采
const enterpriseElegant = POST(`${ApiUrl}/web/portal/api/backapp/operate/portal/basePortal/action/queryList`)

export default {
    homeQueryList,
    enterpriseElegant
}
