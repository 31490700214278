import { GET, POST, PUT, DELETE, ApiUrl } from '@/config/index'

// 我的供应 列表查询
const queryMySupply = POST(`${ApiUrl}/web/process/api/backapp/process/web/supply/action/queryMySupply`)

// 供应数量查询
const querySupplyCount = POST(`${ApiUrl}/web/process/api/backapp/process/web/supply/action/querySupplyCount`)

// 我的供应详情
const getSupplyById = id => {
    return GET(`${ApiUrl}/web/process/api/backapp/process/web/supply/${id}`)()
}

// 发布
const supplySave = POST(`${ApiUrl}/web/process/api/backapp/process/web/supply/`)

// 发布
const supplyEdit = PUT(`${ApiUrl}/web/process/api/backapp/process/web/supply/`)

// 下架
const supplyPullOffShelf = id => {
    return GET(`${ApiUrl}/web/process/api/backapp/process/web/supply/action/pullOffShelf/${id}`)()
}

// 重新上架
const rePutOnShelf = id => {
    return GET(`${ApiUrl}/web/process/api/backapp/process/web/supply/action/putOnShelf/${id}`)()
}

// 删除
const deleteSupplyById = id => {
    return DELETE(`${ApiUrl}/web/process/api/backapp/process/web/supply/${id}`)()
}

export default {
    queryMySupply,
    querySupplyCount,
    getSupplyById,
    supplySave,
    supplyEdit,
    supplyPullOffShelf,
    deleteSupplyById,
    rePutOnShelf
}
