<template>
  <div class="detail">
    <div class="go-back" @click="goBack">
      <div class="icon">
        <a-icon type="left" />
      </div>
      <div class="back-name">后退</div>
    </div>
    <a-form-model :model="formData" label-align="left" :colon="false">
      <div class="module shang-lian">
        <SectionLine title="上链信息" />
        <div class="con">
          <a-row :gutter="24">
            <a-col :span="24">
              <a-form-model-item label="区块链编号">
                {{ formData.traceInfo ? formData.traceInfo.traceKey : '' }}
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="24">
            <a-col :span="12">
              <a-form-model-item label="初次上链时间">
                {{ formData.traceInfo ? formData.traceInfo.createdTime : '' }}
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="修改时间">
                {{ formData.traceInfo ? formData.traceInfo.modifiedTime : '' }}
              </a-form-model-item>
            </a-col>
          </a-row>
        </div>
      </div>
      <div class="module">
        <SectionLine title="基础信息" />
        <div class="con">
          <a-row :gutter="24">
            <a-col :span="8">
              <a-form-model-item :label="formLabel.nameLabel">
                <a-input
                  v-model="formData.name"
                  size="large"
                  disabled
                  allow-clear
                  :placeholder="formLabel.namePlaceholder"
                />
              </a-form-model-item>
            </a-col>
            <a-col v-if="formData.businessType == 1" :span="8">
              <a-form-model-item label="企业简称">
                <a-input
                  v-model="formData.shortName"
                  size="large"
                  disabled
                  allow-clear
                  placeholder="请输入企业简称"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item :label="formLabel.enterpriseCodeLabel">
                <a-input
                  v-model="formData.enterpriseCode"
                  size="large"
                  disabled
                  allow-clear
                  :placeholder="formLabel.enterpriseCodePlaceholder"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="法人代表">
                <a-input
                  v-model="formData.enterpriseCode"
                  size="large"
                  disabled
                  allow-clear
                  placeholder="请输入法人代表"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="生产经营类型">
                <a-select
                  v-model="productionOperation"
                  size="large"
                  mode="multiple"
                  disabled
                  :max-tag-count="1"
                  :max-tag-placeholder="`${
                    productionOperation.length - 1
                  }个其他类型`"
                  allow-clear
                  placeholder="请选择生产经营类型"
                >
                  <a-select-option
                    v-for="item in productionOperationType"
                    :key="item.dictKey"
                    :data-delete="item.deleted"
                  >
                    {{ item.dictValue }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="24">
              <a-form-model-item label="经营范围">
                <a-textarea
                  v-model="formData.businessScope"
                  size="large"
                  disabled
                  :auto-size="{ minRows: 2 }"
                  :max-length="300"
                  placeholder="请填写经营范围"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
        </div>
      </div>
      <div class="module">
        <SectionLine title="联系信息" />
        <div class="con">
          <a-row :gutter="24">
            <a-col :span="12">
              <a-form-model-item label="联系人">
                <a-input
                  v-model="formData.name"
                  size="large"
                  disabled
                  allow-clear
                  placeholder="请输入联系人"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="手机号">
                <a-input
                  v-model="formData.shortName"
                  size="large"
                  disabled
                  allow-clear
                  placeholder="请输入手机号"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="24">
              <a-form-model-item label="经营地址">
                <a-cascader
                  v-model="provinceData"
                  size="large"
                  :options="options"
                  disabled
                  expand-trigger="hover"
                  placeholder="请选择经营地址"
                  :get-popup-container="
                    (triggerNode) => {
                      return triggerNode.parentNode || document.body
                    }
                  "
                />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="24">
              <a-form-model-item label=" ">
                <a-input
                  v-model="formData.address"
                  size="large"
                  disabled
                  allow-clear
                  :max-length="300"
                  placeholder="请填写详细地址"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
        </div>
      </div>
      <div class="module">
        <SectionLine title="详细信息" />
        <div class="con">
          <a-row :gutter="24">
            <a-col :span="24">
              <a-form-model-item label="企业图片">
                <Upload
                  bath-path="merchant"
                  :img-urls.sync="imgUrls"
                  :size="5"
                  :max="5"
                  :is-view="true"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="24">
            <a-col :span="24">
              <a-form-model-item label="公司介绍">
                <div 
                  class="introduction"
                  v-if="formData.introduction"
                  v-html="formData.introduction"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
        </div>
      </div>
      <div class="module">
        <SectionLine title="证件信息" />
        <div class="con table">
          <a-table
            :columns="certificatesColumns"
            :data-source="formData.certificates"
            :pagination="false"
            size="middle"
            row-key="id"
          >
             <template slot="type" slot-scope="scope">
              {{ getTypeName(scope) }}
            </template>
            <template slot="cardImg" slot-scope="scope">
               <img style="width: 26px; height: 36px;padding: 0;" :src="scope | ossImg(65, 90)" @click="handlePreview(scope)">
            </template>
          </a-table>
        </div>
      </div>
      <div class="module">
        <SectionLine title="基地信息" />
        <div class="con table">
          <a-table
            :columns="matrixsColumns"
            :data-source="formData.matrix"
            :pagination="false"
            size="middle"
            row-key="id"
          >
            <template slot="address" slot-scope="text, record">
              <a href="javascript:;" @click="viewMatrix(record)">{{ record.address }}</a>
            </template>
            <template slot="cameraAddress" slot-scope="text, record">
              <span v-if="record.cameraAddress">
                <span v-if="record.cameraAddress.indexOf('[{') > -1">
                 {{ JSON.parse(record.cameraAddress)[0].address }}
                </span>
              <span v-else>
                {{ record.cameraAddress }}
              </span>
          </span>
          <span v-else />
        </template>
          </a-table>
        </div>
      </div>
    </a-form-model>
    <a-modal
      v-model="previewVisible"
      width="30%"
      :destroy-on-close="true"
      :footer="null"
    >
      <img width="100%" :src="previewImage" />
    </a-modal>
    <a-modal
      v-model="showMatrixModal"
      width="880px"
      :destroy-on-close="true"
      :footer="null"
    >
      <matrix :modal-data.sync="modalData" />
    </a-modal>
  </div>
</template>
<script>
import { provinces } from '@/assets/js/province.js'
import matrix from './matrix.vue'
export default {
  components: { matrix },
  data() {
    return {
      options: provinces,
      formData: {
        name: "",
        shortName: "",
        businessType: 1,
        enterpriseCode: "",
        contacts: "",
        phone: "",
        productionOperationType: undefined,
        legalRepresentative: "",
        businessScope: "",
        address: "",
        provinceCode: "",
        provinceName: "",
        cityCode: "",
        cityName: "",
        areaCode: "",
        areaName: "",
        imgUrl: "",
        introduction: "",
        certificates: [],
        type: 1,
        videoUrl: "",
        productTypeName: "",
        productType: "",
        traceInfo: {},
      },
      formLabel: {
        nameLabel: "企业名称",
        namePlaceholder: "请填写企业名称",
        enterpriseCodeLabel: "企业代码",
        enterpriseCodePlaceholder: "请填写18位企业营业执照编号",
      },
      matrixsColumns: [
        {
          dataIndex: 'matrixName',
          key: 'matrixName',
          width: 400,
          ellipsis: true,
          title: '基地名称'
        },
        {
          dataIndex: 'address',
          key: 'address',
          title: '基地位置',
          ellipsis: true,
          scopedSlots: { customRender: 'address' }
        },
        {
          dataIndex: 'cameraAddress',
          key: 'cameraAddress',
          width: 300,
          title: '摄像头地址',
          ellipsis: true,

          scopedSlots: { customRender: 'cameraAddress' }
        }
      ],
      certificatesColumns: [
        {
          dataIndex: 'type',
          key: 'type',
          title: '证件类型',
          width: 400,
          scopedSlots: { customRender: 'type' }
        },
        {
          dataIndex: 'cardNo',
          key: 'cardNo',
          title: '证件编号'
        },
        {
          dataIndex: 'cardImg',
          key: 'cardImg',
          width: 300,
          title: '证件图片',
          scopedSlots: { customRender: 'cardImg' }
        }
      ],
      productionOperationType: [],
      loading: false,
      isView: true,
      provinceData: [],
      imgUrls: "",
      videoUrls: "",
      videoUrlList: [],
      imagesUrl: [],
      showMatrixModal: false,
      modalTitle: undefined,
      modalWidth: undefined,
      componentName: undefined,
      modalData: undefined,
      cardTypeList: [],
      actions: [],
      merchantId: "",
      productionOperation: [],
      oldFormData: undefined,
      previewVisible: false,
      previewImage: undefined,
      pageLoading: false,
      modalImageUrl: "",
    };
  },
  mounted() {
    const id = this.$route.params.id
    id && this.merchantDetail(id);
    this.selectOption();
  },
  methods: {
    goBack() {
      this.$router.push({
        path: '/blockchain/merchant'
      })
    },
    // 证件图片预览
    handlePreview(imgUrl) {
      this.previewImage = this.$utils.ossPath.ossImg(imgUrl, 520, 720)
      this.previewVisible = true
    },
    // 查看基地位置
    viewMatrix(record) {
      this.showMatrixModal = true
      this.modalData = {
        matrixPosition: record.matrixPosition
      }
    },
    // select下拉
    async selectOption() {
      // 12Q0NIZ3 生产经营类型 38MLIIQZ 证件类型
      const params = "12Q0NIZ3,38MLIIQZ";
      try {
        const res = await this.$axios.selectOption(params);
        this.productionOperationType = res.data.body["12Q0NIZ3"];
        this.cardTypeList = res.data.body["38MLIIQZ"];
      } catch (error) {
        if (typeof error == "string") {
          this.$message.error(error);
        } else {
          this.$message.error("查询失败");
        }
      }
    },
    // 企业详情
    async merchantDetail(id) {
      try {
        this.pageLoading = true;
        const res = await this.$axios.merchantDetail(id);
        const data = res.data.body
        this.formData = data;
        this.pageLoading = false;
        this.productionOperation = data.productionOperationType.split(",");
        this.formData.introduction = this.formData.introduction
        ? this.formData.introduction.replace(
            /<img/g,
            '<img style="max-width: 100%;"'
          )
        : "";
        if (this.formData.videoUrl) {
          this.videoUrls = this.formData.videoUrl;
          this.videoUrlList = this.formData.videoUrl;
        }
        if (this.formData.imgUrl) {
          this.imgUrls = this.formData.imgUrl;
        }
        this.formData.businessType = parseInt(this.formData.businessType);
        if (this.formData.businessType == 1) {
          this.formLabel = {
            nameLabel: "企业名称",
            namePlaceholder: "请填写企业名称",
            enterpriseCodeLabel: "企业代码",
            enterpriseCodePlaceholder: "请填写18位企业营业执照编号",
          };
        } else {
          this.formLabel = {
            nameLabel: "个人姓名",
            namePlaceholder: "请填写个人姓名",
            enterpriseCodeLabel: "身份证号",
            enterpriseCodePlaceholder: "请填写个人身份证号",
          };
        }
        const provinces = [];
        if (this.formData.provinceCode) {
          provinces.push(this.formData.provinceCode);
        }
        if (this.formData.cityCode) {
          provinces.push(this.formData.cityCode);
        }
        if (this.formData.areaCode) {
          provinces.push(this.formData.areaCode);
        }
        this.provinceData = provinces;
      } catch (error) {
        if (typeof error == "string") {
          this.$message.error(error);
        } else {
          this.$message.error("查询失败");
        }
      }
    },
    // 获取证件类型名称
    getTypeName(type) {
      let typeName = ''
      this.cardTypeList.map((card) => {
        if (card.dictKey == type) {
          typeName = card.dictValue
        }
      })
      return typeName || type
    }
  },
};
</script>
<style lang="less" scoped>
.detail {
  position: relative;
  .go-back {
    position: absolute;
    top: -30px;
    display: flex;
    align-items: center;
    cursor: pointer;
    .icon {
      width: 20px;
      height: 20px;
      background: #EFEFEF;
      line-height: 20px;
      border: 1px solid #E2E2E2;
      font-size: 12px;
      text-align: center;
      color: #717171;
    }
    .back-name {
      font-size: 10px;
      font-weight: 400;
      color: #898989;
      margin-left: 5px;
    }
  }
  .module {
    /deep/ .ant-form-item {
      margin-bottom: 20px;
      .ant-form-item-label {
        width: 90px;
        float: left;
      }
      .ant-form-item-control-wrapper {
        width: calc(100% - 90px);
        float: left;
        .ant-input[disabled] , .ant-cascader-picker-label, .ant-select-selection__choice__content {
          color: #333;
        }
      }
    }
    .con {
      padding: 20px 10px 5px;
      border-bottom: 1px solid #DFDFDF;
      margin-bottom: 4px;
      img {
        width: 100px;
        height: 100px;
        padding: 8px;
        border: 1px solid #e8e8e8;
        cursor: pointer;
      }
      img + img {
        margin-left: 20px;
      }
      .link {
        color: #20bf8e;
      }
      .introduction {
        border: 1px solid #DFDFDF;
        padding: 10px;
      }
    }
    .table {
      padding-bottom: 20px;
    }
  }
  .shang-lian {
    /deep/ .ant-form-item {
      margin-bottom: 0;
    }
    .con {
      padding: 10px 0 10px 0;
    }
  }
}
</style>