<template>
    <div class="bj_upload_container">
        <a-upload
        name="file"
        :action="action"
        list-type="picture-card"
        accept=".jpg, .png"
        :disabled="disabled"
        :file-list="fileList"
        :data="uploadParam"
        :before-upload="beforeUpload"
        @preview="handlePreview"
        @change="handleChange"
        >
        <div v-if="fileList.length < max && !disabled">
            <a-icon type="plus" />
            <div class="ant-upload-text">
            上传
            </div>
        </div>
        </a-upload>
        <a-modal :visible="previewVisible" :footer="null" @cancel="previewHandleCancel">
        <img alt="example" style="width: 100%" :src="previewImage" />
        </a-modal>
    </div>
</template>

<script>

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
export default {
    name: 'BjUpload',
    model: {
        prop: 'status',
        event: 'uploading'
    },
    props:{
        max: {
            type: Number,
            default: 5
        },
        action: {
            type: String,
            required: true
        },
        defaultList: {
            type: Array,
            default: () => ([])
        },
        disabled: {
            type: Boolean,
            default: false
        },
        status: String
    },
    data() {
        return {
            previewVisible: false,
            previewImage: '',
            fileList: this.defaultList
        }
    },
    methods: {
        previewHandleCancel() {
            this.previewVisible = false;
        },
        async handlePreview(file) {
            if (!file.url && !file.preview) {
                file.preview = await getBase64(file.originFileObj);
            }
            this.previewImage = file.url || file.preview;
            this.previewVisible = true;
        },
        // 上传参数
        uploadParam(file) {
            return {
                file_name: file.name,
                dir_path: 'portal/images/'
            }
        },
        // 上传前的钩子函数
        beforeUpload(file) {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
            if (!isJpgOrPng) {
                this.$message.error('请上传png、jpg格式图片!');
            }
            const isLt5M = file.size / 1024 / 1024 < 5;
            if (!isLt5M) {
                this.$message.error('图片必须小于5MB!');
            }
            return isJpgOrPng && isLt5M;
        },
        handleChange(info) {
            this.$emit('uploading', info.file.status)
            this.fileList = info.fileList;
            if (info.file.status !== 'uploading') {
                const arr = info.fileList.map(item => item.response?.body?.auth_url || item.url).join(';')
                this.$emit('change', arr)
            }
            if (info.file.status === 'done') {
                this.$message.success(`${info.file.name} 文件上传成功`);
            } else if (info.file.status === 'error') {
                this.$message.error(`${info.file.name} 文件上传失败.`);
            } else if (!info.file.status) {
                const delIdx = this.fileList.length - 1
                this.fileList.splice(delIdx,1)
            }
        }
    }
}
</script>

<style lang="less" scoped>
.bj_upload_container {
    line-height: normal;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

/deep/ .ant-modal-wrap {
  display: flex;
  justify-content: center;
}
</style>