import { GET, POST, ApiUrl } from '@/config/index'

// 留言新增
const messageAdd = POST(`${ApiUrl}/web/portal/api/backapp/enterprise/portal/message/`)
// 留言分页查询
const messageQueryByPage = POST(`${ApiUrl}/web/portal/api/backapp/enterprise/portal/message/action/queryByPage`)
// 留言详情
const getMessageById= id => {
  return GET(`${ApiUrl}/web/portal/api/backapp/enterprise/portal/message/${id}`)()
}
// 留言回复
const messageReply = POST(`${ApiUrl}/web/portal/api/backapp/enterprise/portal/message/action/reply`)

export default {
    messageAdd,
    messageQueryByPage,
    getMessageById,
    messageReply
}
