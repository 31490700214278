<template>
  <div class="homepage">
    <div class="bannner-box">
      <a-carousel arrows autoplay effect="fade" dotsClass="indicate">
        <div
          slot="prevArrow"
          class="custom-slick-arrow"
          style="left: 10px; z-index: 1"
        >
          <img src="@/assets/img/left.png" width="100%" height="100%" />
        </div>
        <div slot="nextArrow" class="custom-slick-arrow" style="right: 10px">
          <img src="@/assets/img/right.png" width="100%" height="100%" />
        </div>
        <div class="carousel-img">
          <img
            :src="firstPic.picture"
            @click="openUrl(firstPic.url)"
          />
        </div>
        <template v-for="(item, index) in carouseles">
          <div :key="index" class="carousel-img">
            <img :src="item.picture" @click="openUrl(item.url)" />
          </div>
        </template>
      </a-carousel>
    </div>

    <div class="home-content">
      <SupplyHall :products="products" />
      <!-- <Reference /> -->
      <DemandHall :demands="demands" />
      <a-skeleton :loading="agLoading">
        <AgTech
          v-if="agFlag"
          :expertsData="expertsData"
          :coursesData="coursesData"
          :articlesData="articlesData"
        />
      </a-skeleton>
      <!-- <AgShareList :data="shareList" /> -->
      <EnterpriseElegant :data="enterprises" />
      <div class="home-content">
        <a-skeleton :loading="newsLoading">
          <NewsCenter :news="news" />
        </a-skeleton>
      </div>
      <Partner :partners="partners" />
    </div>

    <div class="message-box" @click="leave">
      <img :src="leaveIcon" />
      <span>我要留言</span>
    </div>
    <a-modal v-model="visible" :width="480" title="留言板" @ok="handleOk">
      <div>
        <a-textarea
          v-model="leaveMsg"
          maxLength="200"
          placeholder="留下你的意见，我们会认真倾听"
          :auto-size="{ minRows: 6, maxRows: 6 }"
        />
      </div>
    </a-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import apiConfig from "@/config/apiConfig";
const { key } =
  apiConfig[process.env.VUE_APP_ENV ? process.env.VUE_APP_ENV : "dev"];

export default {
  components: {},
  computed: {
    ...mapGetters(["isLogin"]),
    ...mapGetters(["userInfo"]),
  },
  data() {
    return {
      userIcon: require("@/assets/img/userIcon.png"),
      leaveIcon: require("@/assets/img/leaveMsg.png"),
      firstPic:{}, //第一张图片信息,解决刷新页面第一张图不展示问题
      carouseles: [], // 轮播图
      partners: [],
      newsTypes: [], // 新闻分类
      news: [], // 新闻资讯
      visible: false, // 留言框
      leaveMsg: "", // 留言
      agLoading: false, // 农技loading
      agFlag: true,
      newsLoading: false, // 新闻资讯loading
      partnerLoading: false, // 合作伙伴loading
      shareLoading: false, // 农机共享loading
      expertsData: {
        moreUrl: "more.com",
        pageResult: {
          dataList: [],
          orderFieldList: [],
          pageCount: "1",
          pageNum: "1",
          pageSize: "10",
          totalCount: "9",
        },
      },
      coursesData: {
        moreUrl: "more.com",
        pageResultDetail: {
          dataList: [
            {
              chapterCount: "3",
              coverImg:
                "https://image.cnhnb.com/image/jpg/miniapp/2021/05/18/932131274ce34a2486aca44a9f511094.jpg",
              createdTime: "2021-05-08 10:13:31",
              detailUrl: "jump.com",
              id: "1",
              name: "艾海舰产后护理",
            },
            {
              chapterCount: "3",
              coverImg:
                "https://image.cnhnb.com/image/jpg/miniapp/2021/05/18/fc971e872d1246c0af7e6a0d1d0110fb.jpg",
              createdTime: "2021-05-08 10:13:31",
              detailUrl: "jump.com",
              id: "2",
              name: "黑猪的产后护理",
            },
            {
              chapterCount: "3",
              coverImg:
                "https://image.cnhnb.com/image/jpg/miniapp/2021/05/18/e996da91dee641a7adf2c83b9742f518.jpg",
              createdTime: "2021-05-08 10:13:31",
              detailUrl: "jump.com",
              id: "3",
              name: "白猪的产后护理",
            },
            {
              chapterCount: "3",
              coverImg:
                "https://image.cnhnb.com/image/jpg/miniapp/2021/05/11/81fc417641ba46748f282eca4566b02f.jpg",
              createdTime: "2021-05-08 10:15:10",
              detailUrl: "jump.com",
              id: "4",
              name: "黑米的高产技巧",
            },
            {
              chapterCount: "0",
              coverImg: "4.png",
              detailUrl: "jump.com",
              id: "5",
              name: "白羊技术课程",
            },
            {
              chapterCount: "1",
              coverImg: "4.png",
              detailUrl: "jump.com",
              id: "6",
              name: "白羊技术课程",
            },
          ],
          orderFieldList: [],
          pageCount: "1",
          pageNum: "1",
          pageSize: "10",
          totalCount: "6",
        },
      },
      articlesData: {
        moreUrl: "more.com",
        pageResultDetail: {
          dataList: [
            {
              categoryName: "农技",
              coverImg:
                "https://image.cnhnb.com/image/jpg/miniapp/2021/05/18/a0a57e3681db4005895d7ec37c5b881a.jpg",
              createdTime: "2021-04-16 14:59:21",
              detailUrl: "jump.com",
              giveLikeCount: "0",
              id: "2",
              name: "养牛基础知识介绍，新手养牛应先了解！",
              readCount: "0",
            },
            {
              categoryName: "农技",
              coverImg:
                "https://image.cnhnb.com/image/jpg/miniapp/2021/05/18/a0a57e3681db4005895d7ec37c5b881a.jpg",
              createdTime: "2021-04-16 15:25:20",
              detailUrl: "jump.com",
              giveLikeCount: "0",
              id: "3",
              name: "养猪技术20项细节，老司机全知道!—猪知乐转载整理",
              readCount: "0",
            },
            {
              categoryName: "农技",
              coverImg:
                "https://yuyang-test.oss-cn-beijing.aliyuncs.com/njkt%2Fimg%2Fcover2.jpeg",
              createdTime: "2021-05-23 16:42:59",
              detailUrl: "jump.com",
              giveLikeCount: "0",
              id: "147",
              name: "12345",
              readCount: "0",
            },
            {
              categoryName: "农技",
              coverImg:
                "https://yuyang-test.oss-cn-beijing.aliyuncs.com/njkt%2Fimg%2Fcat-desk.JPG,https://yuyang-test.oss-cn-beijing.aliyuncs.com/njkt%2Fimg%2Fcover1.jpeg,https://yuyang-test.oss-cn-beijing.aliyuncs.com/njkt%2Fimg%2Fcover1.jpeg",
              createdTime: "2021-05-23 16:47:30",
              detailUrl: "jump.com",
              giveLikeCount: "0",
              id: "148",
              name: "12345",
              readCount: "0",
            },
            {
              categoryName: "农技",
              coverImg:
                "https://yuyang-test.oss-cn-beijing.aliyuncs.com/njkt%2Fimg%2F%E8%A7%86%E9%A2%91.png",
              createdTime: "2021-05-24 10:10:28",
              detailUrl: "jump.com",
              giveLikeCount: "0",
              id: "149",
              name: "12345",
              readCount: "0",
            },
          ],
          orderFieldList: [],
          pageCount: "1",
          pageNum: "1",
          pageSize: "10",
          totalCount: "5",
        },
      },
      shareList: [], // 农机共享列表
      agMachines1: [],
      agMachines2: [],
      ipAddress: {
        lat: "",
        lng: "",
      }, // IP 所在坐标
      enterprises: [], // 企业风采
      products: [],
      demands: [],
    };
  },
  created() {
    console.log("home created");
    this.initCarousel();
    this.queryHomeSupply();
    this.queryHomeDemand();
    this.getAgTech();
    this.initPartnerAndFriends();
    this.newsSelectOptions();
    this.enterpriseElegant();
  },
  mounted() {
    console.log("home mounted");
    this.getAgMachineTypes().then(() => {
      this.getLocationByIp().then(() => {
        this.queryShare();
      });
    });
  },
  methods: {
    openUrl(url) {
      window.open(url, "_blank");
    },
    goLogin() {
      this.$router.push("/login");
    },
    // 当前ip
    async getLocationByIp() {
      const json = await this.$jsonp(
        "https://apis.map.qq.com/ws/location/v1/ip",
        {
          key,
          output: "jsonp",
        }
      );
      const { lat = "", lng = "" } = json ? json.result.location : {};
      this.ipAddress = { lat, lng };
    },
    // 供应大厅查询
    queryHomeSupply() {
      const params = {
        body: {
          params: {},
        },
      };
      this.$axios.queryHomeSupply(params).then((res) => {
        const { code, body, msg } = res.data;
        if (code === "0") {
          this.products = body && body.length > 0 ? body.slice(0, 4) : [];
        } else {
          this.$message.error(msg);
        }
      });
    },
    // 需求大厅查询
    queryHomeDemand() {
      const params = {
        body: {
          orders:"-releaseTime",
          params: {
            shelvesStatus: "1",
          },
        },
      };
      this.$axios.queryHomeDemand(params).then((res) => {
        const { code, body, msg } = res.data;
        if (code === "0") {
          this.demands = JSON.stringify(body) !== "" ? body.slice(0, 6) : [];
        } else {
          this.$message.error(msg);
        }
      });
    },
    // 农技接口
    async getAgTech() {
      const params = {
        body: {
          pageNum: 1,
          pageSize: 10,
          orders:"-createdTime"
        },
      };
      const pros = [
        this.$axios.expertList(params),
        this.$axios.courseList(params),
        this.$axios.articleList(params),
      ];
      this.agLoading = true;
      this.agFlag = false;
      const arr = ["expertsData", "coursesData", "articlesData"];
      try {
        for (let i in pros) {
          const res = await pros[i];
          const { code, body } = res.data;
          if (code === "0") {
            this[`${arr[i]}`] = { ...body };
          } else {
            this[`${arr[i]}`] =
              i === 0
                ? {
                    moreUrl: "",
                    pageResult: {
                      dataList: [],
                    },
                  }
                : {
                    moreUrl: "",
                    pageResultDetail: {
                      dataList: [],
                    },
                  };
            continue;
          }
        }
        this.agLoading = false;
        this.agFlag = true;
      } catch (e) {
        this.agLoading = false;
        this.agFlag = true;
        console.log("error", e);
      }
    },
    // 新闻分类下拉
    newsSelectOptions() {
      const param = {
        body: {},
      };
      this.newsLoading = true;
      Promise.resolve()
        .then(() => {
          return this.$axios.newsSelectOptions(param);
        })
        .then((res) => {
          this.newsTypes = [];
          this.news = [];
          const { code, body } = res.data;
          if (code == "0") {
            if (body.length > 0) {
              this.newsTypes = [...body];
              const promises = this.getNewsQueryPromise(body);
              Promise.all(promises)
                .then((data) => {
                  this.newsLoading = false;
                  if (data) {
                    data.forEach((ele, idx) => {
                      const obj = Object.assign({}, ele.data.body, {
                        newsTypeName: this.newsTypes[idx].name,
                      });
                      obj.typeid = this.newsTypes[idx].id;
                      this.news.push(obj);
                    });
                  }
                })
                .catch((e) => {
                  console.log("proerr", e);
                  this.newsLoading = false;
                });
            } else {
              this.newsLoading = false;
            }
          } else {
            this.newsLoading = false;
          }
        })
        .catch((e) => {
          console.log("proerr", e);
          this.newsLoading = false;
        });
    },
    getNewsQueryPromise(arr = []) {
      if (arr.length === 0) return;
      const types = [...arr];
      return types.map((ele) =>
        this.$axios.newsManageQueryByPage({
          body: {
            pageNum: 1,
            pageSize: 100,
            params: {
              title: "",
              publishStatus: 1,
              newsTypeId: ele.id,
            },
          },
        })
      );
    },
    async initCarousel() {
      const param = {
        body: {
          type: 1, //1：广告管理 2菜单管理 3友情链接 4：合作伙伴
          orders: "+sort",
        },
      };
      const res = await this.$axios.homeQueryList(param);
      if (res.data.body) {
        this.carouseles = [...res.data.body];
        this.firstPic = this.carouseles[0]
        this.carouseles.splice(0,1)
        console.log(this.carouseles)
      }
    },
    // 初始化合作伙伴、友情链接
    initPartnerAndFriends() {
      const param = {
        body: {
          type: 4, //1：广告管理 2菜单管理 3友情链接 4：合作伙伴
          orders: "+sort",
        },
      };
      this.partnerLoading = true;
      this.$axios.homeQueryList(param).then((res) => {
        this.partnerLoading = false;
        if (res.data.body) {
          this.partners =
            JSON.stringify(res.data.body) !== "[]"
              ? res.data.body.slice(0, 12)
              : [];
        } else {
          this.partners = {
            moreUrl: "",
            pageResultDetail: {
              dataList: [],
            },
          };
        }
      });
    },

    // 留言
    leave() {
      if (!this.isLogin) {
        this.$message.warning("请先登录再留言哦！", 10);
      } else {
        this.visible = true;
      }
    },
    handleOk() {
      if (!this.leaveMsg) {
        this.$message.warning("请输入留言内容！", 10);
        return;
      }
      const params = {
        body: {
          content: this.leaveMsg,
          userId: this.userInfo.id,
        },
      };
      this.$axios.messageAdd(params).then((res) => {
        this.visible = false;
        this.leaveMsg = ''
        const { code, msg } = res.data;
        if (code == "0") {
          this.$message.success("留言成功");
        } else {
          this.$message.error(msg);
        }
      });
    },
    // 查询农机共享列表
    async queryShare() {
      const params = {
        body: {
          params: {},
        },
      };
      this.shareLoading = true;
      const res = await this.$axios.queryModelShareList(params);
      const { code, body, msg } = res.data;
      if (code === "0") {
        const result = body.filter((ele) => ele.latitude && ele.longitude);
        result.forEach((obj) => {
          if (obj.typeSecondary) {
            obj.typeSecondary =
              this.transformType(obj.typeSecondary, "agMachines2") || "";
          }
        });
        this.distanceCalculation(result.slice(0, 5));
      } else {
        this.$message.error(msg);
      }
    },
    // 类型转换
    transformType(value, key) {
      const arr = value.split(",").filter((o) => o !== "");
      const data = arr.reduce((pre, cur) => {
        const res = this[key].find((ele) => ele.value === cur).label || "";
        return pre.concat(res, ",");
      }, "");
      return data.substr(0, data.length - 1);
    },
    // 获取农机类型
    async getAgMachineTypes() {
      const res = await this.$axios.getByTypes("NJLX");
      const { code, body } = res.data;
      if (code === "0") {
        body.NJLX.forEach((ele) => {
          this.agMachines1.push({
            value: ele.dictKey,
            label: ele.dictValue,
            id: ele.id,
            children: [],
          });
        });
        this.agMachines1.forEach((ele) => {
          this.getAgMachineTypesLevel2(ele.id);
        });
      }
    },
    // 获取农机类型2
    async getAgMachineTypesLevel2(pid) {
      const res = await this.$axios.getByParentId(pid);
      const { code, body } = res.data;
      if (code === "0") {
        body.forEach((ele) => {
          this.agMachines1
            .find((ele) => ele.id === pid)
            .children.push({
              value: ele.dictKey,
              label: ele.dictValue,
              id: ele.id,
            });
          this.agMachines2.push({
            value: ele.dictKey,
            label: ele.dictValue,
            id: ele.id,
          });
        });
      }
    },
    // 获取坐标位置描述
    async getLocationDescription(lat, lng) {
      const url = "https://apis.map.qq.com/ws/geocoder/v1";
      //发起JSONP请求，获取坐标描述
      const json = await this.$jsonp(url, {
        location: `${lat},${lng}`,
        key,
        output: "jsonp",
      });
      return json && json.result && json.result.formatted_addresses
        ? json.result.formatted_addresses.recommend
        : json.result.address;
    },
    // 距离计算
    async distanceCalculation(arr) {
      const res = [...arr];
      const url = "https://apis.map.qq.com/ws/distance/v1/matrix?parameters";
      const toArr = [];
      res.forEach((obj) => {
        if (obj.latitude && obj.longitude) {
          toArr.push(`${obj.latitude},${obj.longitude}`);
        }
      });
      const json = await this.$jsonp(url, {
        mode: "driving",
        from: `${this.ipAddress.lat},${this.ipAddress.lng}`,
        to: toArr.join(";"),
        key,
        output: "jsonp",
      });
      json.result.rows[0].elements.forEach((ele, index) => {
        res[index].distance = ele.distance;
      });
      res.sort((a, b) => a.distance - b.distance);
      this.shareList = [];
      res.forEach(async (obj) => {
        obj.location = await this.getLocationDescription(
          obj.latitude,
          obj.longitude
        );
        this.shareList.push(obj);
      });
      this.shareLoading = false;
    },
    // 企业风采
    enterpriseElegant() {
      const params = {
        body: {
          params: {
            publishStatus: 1,
          },
        },
      };
      this.$axios.enterpriseElegant(params).then((res) => {
        const { code, body, msg } = res.data;
        if (code == "0") {
          this.enterprises = [...body];
        } else {
          this.$message.error(msg);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import url("../../styles/pages/home.less");

.message-box {
  position: fixed;
  height: 44px;
  right: 0;
  bottom: 100px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
  border-radius: 4px 0px 0px 4px;
  font-size: 12px;
  text-align: center;
  font-weight: 600;
  color: #333333;
  line-height: 44px;
  cursor: pointer;
  padding: 0 12px 0 22px;

  img {
    width: 32px;
    height: 28px;
    position: absolute;
    top: 25%;
    left: -25%;
  }
}
</style>