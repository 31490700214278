<template>
  <div class="edit_container box_shadow_wrap">
    <a-spin :spinning="spinning">
      <div class="global_title">{{ title }}</div>
      <a-form-model class="form_wrap" ref="ruleForm" :model="ruleForm" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
          <template v-if="!spinning">
            <a-form-model-item label="品类" prop="categoryId">
              <a-select v-model="ruleForm.categoryId" placeholder="请选择" @change="selectCategory(ruleForm.categoryId)">
                <a-select-option
                  v-for="(item, index) in category"
                  :key="index"
                  :value="item.id"
                  :data-delete="item.deleted"
                  >{{ item.cateName }}</a-select-option
                >
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="品种" prop="varietyId">
              <a-select v-model="ruleForm.varietyId" placeholder="请选择">
                <a-select-option
                  v-for="(item, index) in varieties"
                  :key="index"
                  :value="item.id"
                  :data-delete="item.deleted"
                  >{{ item.cateName }}</a-select-option
                >
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="产地">
              <area-selection @change="getOriginPlace" v-if="$route.params.type == 'add'"></area-selection>
              <area-selection :default-value="[ruleForm.originProvinceCode, ruleForm.originCityCode, ruleForm.originAreaCode]" @change="getOriginPlace" v-else></area-selection>
            </a-form-model-item>
            <a-form-model-item label="库存地">
              <area-selection @change="getStockPlace" v-if="$route.params.type == 'add'"></area-selection>
              <area-selection :default-value="[ruleForm.stockProvinceCode, ruleForm.stockCityCode, ruleForm.stockAreaCode]" @change="getStockPlace" v-else></area-selection>
            </a-form-model-item>
          </template>
        <a-form-model-item label="联系人" prop="contacts">
          <a-input v-model.trim="ruleForm.contacts" placeholder="请输入联系人" />
        </a-form-model-item>
        <a-form-model-item label="联系人电话" prop="phone">
          <a-input v-model.trim="ruleForm.phone" placeholder="请输入电话" />
        </a-form-model-item>
        <a-form-model-item label="价格">
          <a-input v-model.number="ruleForm.price" default-value="mysite" type="number" placeholder="请输入价格">
            <a-select slot="addonAfter" :default-value="ruleForm.priceUnit"  style="min-width: 60px" @change="workPriceConfirm" :key="ruleForm.priceUnit">
                <a-select-option :value="item.dictKey" v-for="(item,index) in workPriceSelect" :key="index"  :data-delete="item.deleted">
                    {{item.dictValue}}
                </a-select-option>
            </a-select>
          </a-input>
        </a-form-model-item>
        <a-form-model-item label="标题" prop="title">
          <a-input v-model.trim="ruleForm.title" placeholder="输入标题，请勿超过30个字" />
        </a-form-model-item>
        <a-form-model-item ref="picture" label="图片上传" :autoLink="false">
          <div class="clearfix" v-if="!spinning">
            <bj-upload :default-list="previewList" :disabled="disabled" :action="uploadUrl" :max="5" @change="uploadList"></bj-upload>
            <ul>
              <li>
                <div class="tips">文件必须是 jpg 或 png 格式的图片。</div>
              </li>
              <li>
                <div class="tips">最多上传5张图片。</div>
              </li>
              <li>
                <div class="tips">每个文件大小不超过 2M。</div>
              </li>
            </ul>
          </div>
        </a-form-model-item>
        <a-form-model-item label="供应详情" prop="detail">
          <!-- <Editor id="supply-detail" v-model="ruleForm.detail" placeholder="请输入文字..." height="155" @blur="handleEditorBlur" @change="handleContentChange($event, 'detail')" /> -->
          <Editor id="supply-detail" v-model="ruleForm.detail" :isLoading.sync="isLoading" placeholder="请输入文字..." height="155"  />
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
          <a-button @click="cancel">取消</a-button>
          <a-button style="margin-left: 10px" type="primary" @click="confirm">{{ruleForm.auditStatus === "3"?'保存':'发布'}}</a-button>
        </a-form-model-item>
      </a-form-model>
    </a-spin>
  </div>
</template>
<script>
import { ApiUrl } from '@/config/index'
import indexVue from '../index.vue';

export default {
  data() {
    let checkPhone = (rule, value, callback) => {
      let reg1 = /^1\d{10}$/
      let reg2 = /^0\d{2,3}-?\d{7,8}$/
      if (!value) {
        callback(new Error('请输入联系电话'))
      } else if (!reg1.test(value) && !reg2.test(value)) {
        callback(new Error('输入电话不符合要求！'))
      } else {
        callback();
      }
    }
    return {
      spinning: false,
      uploadUrl: `${ApiUrl}/api/backapp/system/fileUpload/oss/input`,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: {},
      previewVisible: false,
      previewImage: '',
      fileList: [],
      typeSupply: [],
      options: [],
      editorValue: '',
      previewList: [],
      disabled: false,
      category: [], // 品类
      varieties: [], // 品种
      areaOptions: [], 
      defaultAreaOptions: [], 
      ruleForm: {
        auditStatus: '', // 审核状态
        categoryId: '', // 品类id
        varietyId: '', // 品种id
        originProvinceCode: '', // 产地省code
        originProvinceName: '', // 产地省name
        originCityCode: '', // 产地市code
        originCityName: '', // 产地市name
        originAreaCode: '', // 产地区code
        originAreaName: '', // 产地区name
        stockProvinceName: '', // 库存省name
        stockProvinceCode: '', // 库存省code
        stockCityCode: '', // 库存地市code
        stockCityName: '', // 库存城市name
        stockAreaCode: '', // 库存地市code
        stockAreaName: '', // 库存地市name
        contacts: '', // 联系人
        phone: '', // 联系电话
        title: '', // 标题
        picture: '', // 图片
        detail: '', // 服务详情
        price: '',
        priceUnitKey: 1,
        priceUnit: '元',
      },
      rules: {
        categoryId: [
          { required: true, message: '请选择品类', trigger: 'blur' }
        ],
        varietyId: [
          { required: true, message: '请选择品种', trigger: 'blur' }
        ],
        contacts: [
          { required: true, message: '请输入联系人', trigger: 'blur' }
        ],
        phone: [
          { validator: checkPhone, trigger: 'change' },
          { required: true, message: '请输入联系电话', trigger: 'change' }
        ],
        detail: [
          { required: true, message: '请输入供应详情', trigger: 'change' }
        ],
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' },
          { min: 1, max: 30, message: '输入标题，请勿超过30个字', trigger: 'blur' }
        ],
        price: [
          { required: true, message: '请输入价格', trigger: 'change' }
        ]
      },
      workPriceSelect: [],
      isLoading: false
    };
  },
  computed: {
    title() {
      return this.$route.params.id === '0' ? '供应新增' : '供应编辑'
    }
  },
  created() {
    if (this.$route.params.type === 'edit') {
      this.getDetail()
      this.queryCategory()
    } else {
      this.queryCategory()
    }
  },
  mounted() {
      this.getPriceTypes()
  },
  methods: {
    // 初始化默认值价格单位
    workPriceConfirm(key) {
        const currentItem = this.workPriceSelect.find(item => item.dictKey === key);
        if(currentItem) {
            this.ruleForm.priceUnitKey = currentItem.dictKey
            this.ruleForm.priceUnit = currentItem.dictValue
        }
    },
    // 获取价格单位下拉数据
    async getPriceTypes() {
      const res = await this.$axios.getByTypes("CZT0DXY3");
      const { code, body } = res.data;
      if (code === "0") {
        this.workPriceSelect = body.CZT0DXY3
        this.ruleForm.priceUnitKey = this.workPriceSelect[0].dictKey
        this.ruleForm.priceUnit = this.workPriceSelect[0].dictValue
      }
    },
    imgChange(url) {
      this.ruleForm.picture = url
    },
    // 查询品类
    async queryCategory() {
      const res = await this.$axios.queryCategory()
      if (res.data.code === '0') {
        this.category = [...res.data.body]
      } 
    },
    // 查询品种
    async queryVarietyById(id) {
      const res = await this.$axios.queryVarietyById(id)
      console.log('queryVarietyById', res);
      if (res.data.code === '0') {
        this.varieties = [...res.data.body]
      }
    },
    // 产地
    getOriginPlace(e) {
      console.log('getOriginPlace', e);
      const len = e.id ? e.id.length : 0
      if (len === 1) {
        this.ruleForm.originProvinceName = e.address
        this.ruleForm.originProvinceCode = e.id[0]
      }
      if (len === 2) {
        this.ruleForm.originCityName = e.address.replace(this.ruleForm.originProvinceName, '')
        this.ruleForm.originCityCode = e.id[1]
      }
      if (len === 3) {
        this.ruleForm.originAreaName = e.address.replace(this.ruleForm.originProvinceName, '').replace(this.ruleForm.originCityName, '')
        this.ruleForm.originAreaCode = e.id[2]
      }
    },
    // 库存地
    getStockPlace(e) {
      console.log('getStockPlace', e);
      const len = e.id ? e.id.length : 0
      if (len === 1) {
        this.ruleForm.stockProvinceName = e.address
        this.ruleForm.stockProvinceCode = e.id[0]
      }
      if (len === 2) {
        this.ruleForm.stockCityName = e.address.replace(this.ruleForm.originProvinceName, '')
        this.ruleForm.stockCityCode = e.id[1]
      }
      if (len === 3) {
        this.ruleForm.stockAreaName = e.address.replace(this.ruleForm.originProvinceName, '').replace(this.ruleForm.originCityName, '')
        this.ruleForm.stockAreaCode = e.id[2]
      }
    },
    selectCategory(id) {
      this.ruleForm.varietyId = ''
      this.queryVarietyById(id)
    },
    async getDetail() {
      const id = this.$route.params.id
      this.spinning = true;
      const res = await this.$axios.getSupplyById(id)
      console.log('getSupplyById', res);
      this.spinning = false;
      if (res.data.code === '0') {
        const result = res.data.body
        this.ruleForm = { ...result }
        if (this.ruleForm.categoryId) {
          this.queryVarietyById(this.ruleForm.categoryId)
        }
        if (this.ruleForm.picture) {
          this.initPreview(this.ruleForm.picture.split(';'))
        }
      }
    },
    // 初始化上传图片
    initPreview(arr = []) {
      this.previewList = arr.map((item, index) => ({uid: `-${index + 1}`, name: 'image.png', status: 'done', url: item}))
    },
    handleContentChange(html, id) {
      if (id === 'detail') {
        this.ruleForm.detail = html.replace(/<[^<>]+>/g, '')
      }
    },
    handleEditorBlur(html) {
    },
    areaChange() {

    },
    onSubmit() {
      console.log('submit!', this.form);
    },
    // 图片上传
    uploadList(e) {
      this.ruleForm.picture = e
      this.$refs.picture.onFieldChange()
    },
    previewHandleCancel() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleChange({ fileList }) {
      this.fileList = fileList;
    },
    confirm() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.isLoading) return
          this.saveFunc()
        } else {
          console.log('请填写必填项!');
          return false;
        }
      });
    },
    saveFunc() {
      if (this.ruleForm.auditStatus === '2') {
        this.ruleForm.auditStatus = '0'
      }
      const params = { 
        body: {...this.ruleForm }
      }
      if (this.$route.params.type === 'edit') {
        params.body.id = this.$route.params.id
      }
      const method = this.$route.params.type === 'edit' ? 'supplyEdit' : 'supplySave'
      this.$axios[method](params).then(res => {
        console.log('supplyFunc', res);
        if (res.data.code === '0') {
          this.$message.success(`${this.ruleForm.auditStatus === "3"?'保存':'发布'}成功!`)
          this.$router.push('/personalCenter/mySupply')
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    cancel() {
      this.$router.push('/personalCenter/mySupply')
    },
  },
};
</script>

<style scoped lang="less">
@import "../../../styles/pages/personalcenter.less";
.edit_container {
  margin-top: 2px;
}
.form_wrap {
  margin-top: 16px;
}
.tips {
  // margin-top: -10px;
  line-height: normal;
  color: #ccc;
}
</style>
