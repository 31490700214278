<template>
  <div class="personal-center">
    <div class="personal-header">
      <div class="header1">
        <img :src="userIcon" style="width: 64px" />
        <div style="display: inline-block; margin-left: 15px">
          <span class="greet">{{ userInfo.userName }}</span
          ><span class="greet" style="margin-left: 32px">{{ greet }}</span>
          <span class="last-time"
            >上次登录时间：{{ userInfo.lastLoginTime }}</span
          >
        </div>
      </div>
      <div class="header2">
        <div>您感兴趣的内容</div>
        <template v-for="(item, index) in myLikeArr">
          <div :key="index" class="like-item">
            {{ item.name }}
          </div>
        </template>
        <div class="edit-text" @click="addLike">添加</div>
        <div class="edit-text" @click="editLike">修改</div>
      </div>
      <div
        :class="[
          'certification_btn',
          certificateStatus > 0 ? 'active_certification_btn' : '',
        ]"
        @click="goAttestation"
      >
        {{ attestation }}<a-icon :style="{ marginLeft: '5px' }" type="right" />
      </div>
    </div>
    <div class="personal-container">
      <div class="personal-left">
        <a-menu
          class="menuStyle"
          :open-keys="openKeys"
          :default-selected-keys="defaultSelectedKeys"
          :selectedKeys="[onRoutes]"
          mode="inline"
          :style="{ backgroundColor: '#F5F5F5' }"
          @openChange="onOpenChange"
        >
          <template v-for="item in menus">
            <template v-if="!item.submenus">
              <a-menu-item :key="item.path || item.title" @click="menuClick">
                <img class="menu_icon" :src="item.icon" />
                <span>{{ item.title }}</span>
              </a-menu-item>
            </template>
            <template v-else>
              <a-sub-menu :key="item.path || item.title">
                <span slot="title"
                  ><img class="menu_icon" :src="item.icon" /><span>{{
                    item.title
                  }}</span></span
                >
                <a-menu-item
                  v-for="sub in item.submenus"
                  :key="sub.path"
                  @click="menuClick"
                >
                  <span>{{ sub.title }}</span>
                </a-menu-item>
              </a-sub-menu>
            </template>
          </template>
        </a-menu>
      </div>
      <div :class="['personal-right', showBoxShadow ? 'box_shadow' : '']">
        <!-- 微应用挂载节点 -->
        <div id="child-container" :style="containerStyle">
          <div v-if="microAppLoading" class="spin-contain">
            <a-spin size="large"> </a-spin>
          </div>
        </div>
        <router-view v-if="!microAppsActive" />
      </div>
    </div>
    <a-modal
      v-model="likeModal"
      class="likeModalStyle"
      title="请选择感兴趣的内容"
      width="618px"
      @cancel="handleCancel"
      @ok="handleOk"
    >
      <div class="variety">
        <span class="label">品种：</span>
        <template v-for="(variety, idx) in likeArr">
          <span
            :key="idx"
            :style="{
              color:
                likeForm.varietiesId === variety.id ? '#20BF8E' : '#333333',
            }"
            @click="selectVariety(variety)"
            >{{ variety.name }}</span
          >
        </template>
      </div>
      <a-divider />
      <div class="period">
        <span class="label">物候期：</span>
        <span class="mult">(可多选)</span>
        <template v-for="(item, idx) in periods">
          <div
            :class="{'unselect': true, 'select': likeForm.phenophaseId && likeForm.phenophaseId.split(',').includes(item.id.toString()) }"
            @click="selectChild('phenophaseId', item.id)"
            :key="idx"
          >
            {{ item.name }}
          </div>
        </template>
      </div>
      <div class="period">
        <span class="label">技术：</span>
        <span class="mult">(可多选)</span>
        <template v-for="(item, idx) in techs">
          <div
            :class="{'unselect': true, 'select': likeForm.technologyId && likeForm.technologyId.split(',').includes(item.id.toString()) }"
            @click="selectChild('technologyId', item.id)"
            :key="idx"
          >
            {{ item.name }}
          </div>
        </template>
      </div>
    </a-modal>
  </div>
</template>

<script>
let container;
import { mapGetters } from "vuex";
import { registerMicroApps, start} from "@ice/stark";
import { setBasename } from "@ice/stark-app";
const url = "https://zjfw.tzszyygs.com/"
import store from "@/store";
const njSources = {
  ABcDvTbB20: [
    `${url}/tech/app.js`,
    `${url}/tech/js/home.js`,
    `${url}/tech/js/activity.js`,
    `${url}/tech/js/activityDetail.js`,
    `${url}/tech/js/expert.js`,
    `${url}/tech/js/expertDetail.js`,
    `${url}/tech/js/course.js`,
    `${url}/tech/js/courseDetail.js`,
    `${url}/tech/js/courseChapter.js`,
    `${url}/tech/js/live.js`,
    `${url}/tech/js/liveDetail.js`,
    `${url}/tech/js/expertStudio.js`,
    `${url}/tech/js/expertStudioHome.js`,
    `${url}/tech/js/vendors~liveDetail.js`,
    `${url}/tech/js/vendors~courseDetail~expertStudioHome.js`,

    `${url}/tech/js/article.js`,
    `${url}/tech/js/articleDetail.js`,
    `${url}/tech/js/help.js`,
    `${url}/tech/js/HelpAdd.js`,
    `${url}/tech/js/helpDetail.js`,
    `${url}/tech/js/myArticles.js`,
    `${url}/tech/js/myExperts.js`,
    `${url}/tech/js/myCourses.js`,
    `${url}/tech/js/myMsgs.js`,
    `${url}/tech/css/app.css`,
    `${url}/tech/css/home.css`,
    `${url}/tech/css/activity.css`,
    `${url}/tech/css/activityDetail.css`,
    `${url}/tech/css/expert.css`,
    `${url}/tech/css/expertDetail.css`,
    `${url}/tech/css/course.css`,
    `${url}/tech/css/courseDetail.css`,
    `${url}/tech/css/courseChapter.css`,
    `${url}/tech/css/live.css`,
    `${url}/tech/css/expertStudio.css`,
    `${url}/tech/css/expertStudioHome.css`,
    `${url}/tech/css/liveDetail.css`,
    `${url}/tech/css/article.css`,
    `${url}/tech/css/articleDetail.css`,
    `${url}/tech/css/help.css`,
    `${url}/tech/css/HelpAdd.css`,
    `${url}/tech/css/helpDetail.css`,
    `${url}/tech/css/myArticles.css`,
    `${url}/tech/css/myExperts.css`,
    `${url}/tech/css/myCourses.css`,
    `${url}/tech/css/myMsgs.css`,
  ],
  ABcDvTbB30: [
    `${url}/tech/app.js`,
    `${url}/tech/js/home.js`,
    `${url}/tech/js/myMsgs.js`,
    `${url}/tech/js/article.js`,
    `${url}/tech/js/articleDetail.js`,
    `${url}/tech/css/app.css`,
    `${url}/tech/css/home.css`,
    `${url}/tech/css/myMsgs.css`,
    `${url}/tech/js/expertStudio.js`,
    `${url}/tech/js/expertStudioArticle.js`,
    `${url}/tech/js/ExpertStudioArticleData.js`,
    `${url}/tech/js/expertStudioCourse.js`,
    `${url}/tech/js/ExpertStudioCourseData.js`,
    `${url}/tech/js/expertStudioHome.js`,
    `${url}/tech/js/expertStudioLive.js`,
    `${url}/tech/js/ExpertStudioLiveDetails.js`,
    `${url}/tech/js/ExpertStudioSetting.js`,
    `${url}/tech/js/vendors~courseDetail~expertStudioHome.js`,
    `${url}/tech/css/expertStudio.css`,
    `${url}/tech/css/expertStudioArticle.css`,
    `${url}/tech/css/ExpertStudioArticleData.css`,
    `${url}/tech/css/expertStudioCourse.css`,
    `${url}/tech/css/ExpertStudioCourseData.css`,
    `${url}/tech/css/expertStudioHome.css`,
    `${url}/tech/css/expertStudioLive.css`,
    `${url}/tech/css/ExpertStudioLiveDetails.css`,
    `${url}/tech/css/ExpertStudioSetting.css`,
    `${url}/tech/js/myArticles.js`,
    `${url}/tech/js/myExperts.js`,
    `${url}/tech/js/myCourses.js`,
    `${url}/tech/css/myArticles.css`,
    `${url}/tech/css/myExperts.css`,
    `${url}/tech/css/myCourses.css`,
    `${url}/tech/css/article.css`,
    `${url}/tech/css/articleDetail.css`,
    `${url}/tech/js/expert.js`,
    `${url}/tech/js/expertDetail.js`,
    `${url}/tech/js/course.js`,
    `${url}/tech/js/courseDetail.js`,
    `${url}/tech/css/expert.css`,
    `${url}/tech/css/expertDetail.css`,
    `${url}/tech/css/course.css`,
    `${url}/tech/css/courseDetail.css`
  ],
};

export default {
  name: "PersonalCenter",
  components: {},
  data() {
    return {
      microAppsActive: false,
      userIcon: require("@/assets/img/userIcon.png"),
      myLikeArr: [],
      likeArr: [],
      likeForm: {
        // 我感兴趣参数
        farmerId: "", // 农户标识
        varietiesId: "", // 品种标识
        phenophaseId: "", // 物候期标识
        technologyId: "", // 技术标识
      },
      periods: [], // 物候期
      techs: [], // 技术
      likeModal: false,
      isAdd: true,
      rootSubmenuKeys: [],
      openKeys: [],
      defaultSelectedKeys: [], // 菜单默认选中key
      // 个人中心-农技学堂菜单
      njPaths: [
        "/myNjkt/myExperts",
        "/myNjkt/myCourses",
        "/myNjkt/myArticles",
        "/myNjkt/myMsgs",
        "/myNjkt/expertStudio",
        "/myNjkt/expertStudio/course",
        "/myNjkt/expertStudio/article",
      ]
    };
  },
  props: {},
  activated() {
    // this.defaultSelectedKeys = ['/personalCenter/index'];
    // this.$router.push('/personalCenter/index')
    // this.openKeys = ['首页']
    
    console.log('person activated',this.userInfo);
    this.likeForm.farmerId = this.userInfo.id;
    this.getLikes();
    this.Verify()
    this.userInfo.userType === "ABcDvTbB20"
      ? ''
      : this.getExpertLikes();
    this.getCertificationStatus();
  },
  created() {
    console.log("person created");
  },
  mounted() {
    const mapKeys = (list) => {
      let arr = [];
      list.forEach((item) => {
        arr.push(item.path || item.title);
        if (item.submenus) arr.push(...mapKeys(item.submenus));
      });
      return arr;
    };

    this.rootSubmenuKeys = mapKeys(this.menus);
    const userType = this.userInfo.userType || "ABcDvTbB20";

    container = document.getElementById("child-container");
    console.log("per mounted", container);

    registerMicroApps([
      {
        name: "myNjkt",
        activePath: `${process.env.BASE_URL}myNjkt`,
        title: "",
        sandbox: false,
        url: njSources[userType],
        container,
      },
    ]);
    start({
      prefetch: true,
      // 微应用 route 变化时的回调
      onRouteChange: (_, pathname) => {
        console.log("onRouteChange", _, pathname);
        let path = "";
        if (_.includes("http")) {
          path = pathname
        } else {
          path = _
        }

        // 个人中心-农技学堂-我关注的专家、课程、文章： 点击详情 => 跳转至首页-农技学堂
        if (path.includes('myNjkt') && !this.njPaths.includes(path) && !path.startsWith('/myNjkt/expertStudio')) {
          path = path.replace('myNjkt', 'njkt')
          setTimeout(() => {
            this.$router.replace(path).catch(() => {}); // 处理微应用间跳转无法触发 Vue Router 响应
          })
        }
      },
      // 微应用开始被激活的回调
      onActiveApps: (activeApps) => {
        console.log("onActiveApps", activeApps);
        this.microAppsActive = (activeApps || []).length;
        console.log("microAppsActive", this.microAppsActive);
      },
      // 微应用渲染前的回调
      onAppEnter: (appConfig) => {
        const { activePath } = appConfig;
        setBasename(activePath);
      },
      // 微应用开始加载的回调
      onLoadingApp: (appConfig) => {
        console.log("onLoadingApp", appConfig);
        store.commit("setMicroAppLoading");
      },
      // 微应用结束加载的回调
      onFinishLoading: (appConfig) => {
        console.log("onFinishLoading", appConfig);
        store.commit("setMicroAppLoading");
      },
    });
  },
  methods: {
    // 选择品种
    selectVariety({ id, phenophaseDtoList = [], technologyDtoList = [] }) {
      this.likeForm.varietiesId = id;
      this.periods = [...phenophaseDtoList];
      this.techs = [...technologyDtoList];
      this.likeForm.phenophaseId = "";
      this.likeForm.technologyId = "";
    },
    // 选择无后期或技术
    selectChild(type, id) {
      const val = this.likeForm[type] ? this.likeForm[type].split(",") : [];
      if (val.includes(id)) {
        val.splice(val.indexOf(id), 1);
      } else {
        val.push(id);
      }
      this.likeForm[type] = val.join(",");
    },
    // 添加喜欢标签
    handleOk() {
      localStorage.removeItem("varietiesId");
      localStorage.removeItem("phenophaseId");
      localStorage.removeItem("technologyId");
      const params = {
        body: { ...this.likeForm },
      };
      const method = this.isAdd ? "createLike" : "editLike";
      const successMag = this.isAdd ? "添加成功" : "修改成功";
      this.$axios[method](params).then((res) => {
        console.log("createLike", res);
        if (res.data.code == "0") {
          this.$message.success(successMag);
          localStorage.setItem("varietiesId", this.likeForm.varietiesId);
          localStorage.setItem("phenophaseId", this.likeForm.phenophaseId);
          localStorage.setItem("technologyId", this.likeForm.technologyId);
          this.likeModal = false;
          this.getMyLikes();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    Verify() {
      if (this.userInfo.userType == 'ABcDvTbB20') {
        let postData = {
          body: {
            avatar: 'https://yuyang-prod.oss-cn-beijing.aliyuncs.com/njkt%2Fimg%2F%E9%BB%98%E8%AE%A4%E5%A4%B4%E5%83%8F.png',
            middleUserId: this.userInfo.id,
            nickName: this.userInfo.nickName,
            telephone: this.userInfo.phone
          }
        }
        this.$axios.sendUserInfo(postData).then(() => {
          // this.getUserInterestedTags();
          // this.getEvaluateTags();
          this.getMyLikes()
        }).catch((err) => {
          console.log(err)
          this.getMyLikes()
        });
      }
    },
    getLikes() {
      this.$axios.getLikeList().then((res) => {
        console.log("getLikeList", res);
        this.likeArr = [];
        if (res.data.code == "0") {
          this.likeArr = [...res.data.body];
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    // 专家感兴趣的标签
    getExpertLikes() {
      const id = this.likeForm.farmerId;
      this.$axios.expertInterest(id).then((res) => {
        this.myLikeArr = [];
        if (res.data.code == "0") {
          const data = res.data.body
          this.myLikeArr = [{...data}]
          if (data.phenophaseDtoList && data.phenophaseDtoList.length) {
            this.myLikeArr = [...this.myLikeArr, ...data.phenophaseDtoList]
          }
          if (data.technologyDtoList && data.technologyDtoList.length) {
            this.myLikeArr = [...this.myLikeArr, ...data.technologyDtoList]
          }
          this.$forceUpdate();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    getMyLikes() {
      this.$axios.getMyLikeList(this.userInfo.id).then((res) => {
        console.log("getMyLikeList", res);
        this.myLikeArr = [];
        if (res.data.code == "0") {
          const data = res.data.body
          this.myLikeArr = [{...data}]
          this.likeForm.varietiesId = data.id;
          const times = [];
          const techs = [];
          if (data.phenophaseDtoList) {
            data.phenophaseDtoList.forEach((o) => {
              times.push(o.id);
              this.myLikeArr.push(o)
            });
          }
          this.likeForm.phenophaseId = times.join(",");
          if (data.technologyDtoList) {
            data.technologyDtoList.forEach((o) => {
              techs.push(o.id);
              this.myLikeArr.push(o)
            });
          }
          this.likeForm.technologyId = techs.join(",");
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    addLike() {
      this.likeModal = true;
      this.isAdd = true;
      if (this.likeForm.varietiesId) {
        this.periods =
          this.likeArr.find((o) => o.id === this.likeForm.varietiesId)
            .phenophaseDtoList || [];

        this.techs =
          this.likeArr.find((o) => o.id === this.likeForm.varietiesId)
            .technologyDtoList || [];
      }
    },
    editLike() {
      this.likeModal = true;
      this.isAdd = false;
    },
    menuClick({ key }) {
      this.$router.push({
          path: key,
        });
      // if (key.indexOf("myNjkt") > 0) {
      //   return;
      // } else {
      //   this.$router.push({
      //     path: key,
      //   });
      // }
    },
    handleCancel() {
      this.likeModal = false;
    },
    likeChange() {},
    goAttestation() {
      let url = "/personalCenter/authentication";
      if (this.certificateStatus == 1)
        url = "/personalCenter/personalCertification";
      if (this.certificateStatus == 2)
        url = "/personalCenter/enterpriseCertification";
      this.$router.push({ path: url });
    },
    // 查询认证状态
    async getCertificationStatus() {
      const {
        data: { code, msg, body },
      } = await this.$axios.getCertification({});
      if (code != 0) return this.$message.error(msg);
      this.$store.commit("setCertification", body);
    },
    // SubMenu 展开/关闭的回调
    onOpenChange(openKeys) {
      console.log(openKeys);
      const latestOpenKey = openKeys.find(
        (key) => this.openKeys.indexOf(key) === -1
      );
      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openKeys = openKeys;
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : [];
      }
    },
  },
  // 从个人中心下拉菜单跳转过来得到展开的菜单节点
  watch: {
    $route(to) {
      var openKey = [];
      console.log(this.menus);
      this.menus.map((s) => {
        if (s.submenus) {
          s.submenus.map((i) => {
            if (i.title === to.meta.title) {
              openKey = [s.title];
            }
          });
        }
      });
      this.onOpenChange(openKey);
    },
  },
  computed: {
    ...mapGetters(["userInfo"]),
    ...mapGetters(["microAppLoading"]),
    menus() {
      const menus1 = [
        {
          title: "消息",
          path: "/myNjkt/myMsgs",
          icon: require("@/assets/img/icon/message.png"),
        },
        {
          title: "首页",
          path: "/personalCenter/index",
          icon: require("@/assets/img/icon/home.png"),
        },
        {
          title: "留言板",
          path: "/personalCenter/messageBoard",
          icon: require("@/assets/img/icon/message_board.png"),
        },
        {
          title: "我的收藏",
          icon: require("@/assets/img/icon/collect.png"),
          submenus: [
            {
              title: "供应收藏",
              path: "/personalCenter/supplyCollection",
            },
            {
              title: "需求收藏",
              path: "/personalCenter/demandCollection",
            },
          ],
        },
        {
          title: "供应管理",
          icon: require("@/assets/img/icon/supplier.png"),
          submenus: [
            {
              title: "我的供应",
              path: "/personalCenter/mySupply",
            },
            {
              title: "我的服务情况",
              path: "/personalCenter/myService",
            },
          ],
        },
        {
          title: "需求管理",
          icon: require("@/assets/img/icon/demand_through_train.png"),
          submenus: [
            {
              title: "我的需求",
              path: "/personalCenter/myNeeds",
            },
            // {
            //   title: "需求收藏",
            //   path: "/personalCenter/demandCollection",
            // },
          ],
        },
        {
          title: "订单管理",
          icon: require("@/assets/img/icon/order.png"),
          submenus: [
            {
              title: "需求订单",
              path: "/personalCenter/demandOrder",
            },
            {
              title: "订单执行",
              path: "/personalCenter/serviceOrder",
            },
          ],
        },
        {
          title: "农技学堂",
          path: "/personalCenter/school",
          icon: require("@/assets/img/icon/direct_broadcasting_room.png"),
          submenus: [
            {
              title: "我关注的专家",
              path: "/myNjkt/myExperts",
            },
            {
              title: "我收藏的课程",
              path: "/myNjkt/myCourses",
            },
            {
              title: "我收藏的文章",
              path: "/myNjkt/myArticles",
            },
          ],
        },
        // {
        //   title: "农机预约",
        //   icon: require("@/assets/img/icon/appointment.png"),
        //   submenus: [
        //     {
        //       title: "我的预约",
        //       path: "/personalCenter/myReservation",
        //     },
        //   ],
        // },
      ];
      const menus2 = [
        {
          title: "消息",
          path: "/myNjkt/myMsgs",
          icon: require("@/assets/img/icon/message.png"),
        },
        {
          title: "首页",
          path: "/personalCenter/index",
          icon: require("@/assets/img/icon/home.png"),
        },
        {
          title: "留言板",
          path: "/personalCenter/messageBoard",
          icon: require("@/assets/img/icon/message_board.png"),
        },
        {
          title: "我的收藏",
          icon: require("@/assets/img/icon/collect.png"),
          submenus: [
            {
              title: "供应收藏",
              path: "/personalCenter/supplyCollection",
            },
            {
              title: "需求收藏",
              path: "/personalCenter/demandCollection",
            },
          ],
        },
        {
          title: "供应管理",
          icon: require("@/assets/img/icon/supplier.png"),
          submenus: [
            {
              title: "我的供应",
              path: "/personalCenter/mySupply",
            },
            {
              title: "我的服务情况",
              path: "/personalCenter/myService",
            },
          ],
        },
        {
          title: "需求管理",
          icon: require("@/assets/img/icon/demand_through_train.png"),
          submenus: [
            {
              title: "我的需求",
              path: "/personalCenter/myNeeds",
            },
          ],
        },
        {
          title: "订单管理",
          icon: require("@/assets/img/icon/order.png"),
          submenus: [
            {
              title: "需求订单",
              path: "/personalCenter/demandOrder",
            },
            {
              title: "订单执行",
              path: "/personalCenter/serviceOrder",
            },
          ],
        },
        {
          title: "农机预约",
          icon: require("@/assets/img/icon/appointment.png"),
          submenus: [
            {
              title: "我的预约",
              path: "/personalCenter/myReservation",
            },
          ],
        },
        {
          title: "农技学堂",
          path: "/personalCenter/school",
          icon: require("@/assets/img/icon/direct_broadcasting_room.png"),
          submenus: [
            {
              title: "我关注的专家",
              path: "/myNjkt/myExperts",
            },
            {
              title: "我收藏的课程",
              path: "/myNjkt/myCourses",
            },
            {
              title: "我收藏的文章",
              path: "/myNjkt/myArticles",
            },
          ],
        },
        {
          title: "农技专家",
          icon: require("@/assets/img/icon/njzj.png"),
          submenus: [
            {
              title: "专家工作台",
              path: "/myNjkt/expertStudio",
            },
            {
              title: "课程管理",
              path: "/myNjkt/expertStudio/course",
            },
            {
              title: "文章管理",
              path: "/myNjkt/expertStudio/article",
            },
          ],
        },
      ];
      return this.userInfo.userType === "ABcDvTbB20" ? menus1 : menus2;
    },
    onRoutes() {
      const route = this.$route;
      const { path, meta = {} } = route;
      if (meta.parentPath) {
        return meta.parentPath;
      }
      return path;
    },
    greet() {
      let today = new Date();
      if (today.getHours() >= 0 && today.getHours() < 12) {
        return "上午好";
      }
      if (today.getHours() >= 12 && today.getHours() < 18) {
        return "下午好";
      }
      return "晚上好";
    },
    periodsObject() {
      return (id) => {
        const val = this.likeForm.phenophaseId
          ? this.likeForm.phenophaseId.split(",")
          : [];
        const flag = val.includes(id);
        return {
          unselect: true,
          select: flag,
        };
      };
    },
    techsObject() {
      return (id) => {
        const val = this.likeForm.technologyId
          ? this.likeForm.technologyId.split(",")
          : [];
        const flag = val.includes(id);
        return flag
      };
    },
    containerStyle() {
      let regx =
        /^\/myNjkt\/|\/myNjkt\/myExperts|\/myNjkt\/myCourses|\/myNjkt\/myArticles|\/myNjkt\/myMsgs*/g;
      return regx.test(this.$route.path) ? { height: "100%" } : { height: 0 };
    },
    showBoxShadow() {
      let regx =
        /^\/myNjkt\/|\/myNjkt\/myExperts|\/myNjkt\/myCourses|\/myNjkt\/myArticles*/g;
      return regx.test(this.$route.path);
    },
    attestation() {
      if (this.$store.state.certificateStatus == 1) return "个人认证";
      else if (this.$store.state.certificateStatus == 2) return "企业认证";
      else return "前往认证";
    },
    certificateStatus() {
      return this.$store.state.certificateStatus;
    },
  },
};
</script>

<style scoped lang="less">
/deep/ .ant-modal {
  .ant-modal-body {
    padding: 0;
  }
}
.likeModalStyle {
  div {
    padding: 0 36px;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 20px;
    span {
      cursor: pointer;
      padding: 5px 12px;
    }
    span.label {
      padding: 5px 0;
    }
    span.mult {
      font-size: 10px;
      font-weight: 400;
      color: #8c8c8c;
      line-height: 20px;
      padding: 5px 0;
    }
  }
  div.period {
    padding: 11px 36px;
    .unselect {
      cursor: pointer;
      display: inline-block;
      font-size: 14px;
      font-weight: 400;
      color: #8c8c8c;
      line-height: 22px;
      border-radius: 26px;
      border: 1px solid #d9d9d9;
      padding: 0 9px;
      margin: 0 12px;
    }
    .select {
      cursor: pointer;
      display: inline-block;
      font-size: 14px;
      font-weight: 400;
      color: #20bf8e;
      line-height: 22px;
      border-radius: 26px;
      border: 1px solid #20bf8e;
      padding: 0 9px;
      margin: 0 12px;
    }
  }
}
.personal-center {
  margin: 0 120px;
  // height: calc(100% - 40px);
  .personal-header {
    position: relative;
    height: 172px;
    padding: 21px;
    margin-bottom: 24px;
    background: linear-gradient(180deg, @primary-color 0%, #03c19b 100%);
    box-shadow: 0px 2px 5px 2px rgba(202, 221, 247, 0.8);
    .header1 {
      display: flex;
      align-items: center;
      flex-direction: row;
      .greet {
        font-size: 22px;
        font-weight: 500;
        color: #ffffff;
        line-height: 30px;
      }

      .last-time {
        display: block;
        font-size: 16px;
        font-weight: 400;
        color: #ffffff;
        line-height: 26px;
      }
    }

    .header2 {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      flex-direction: row;
      margin-top: 33px;
      font-size: 18px;
      font-weight: 500;
      color: #ffffff;
      line-height: 25px;
      > div {
        display: inline-block;
      }
      .like-item {
        display: inline-block;
        text-align: center;
        margin-left: 15px;
        width: 102px;
        height: 28px;
        background: #ffffff;
        border-radius: 26px;
        font-size: 14px;
        font-weight: 400;
        color: @primary-color;
        line-height: 20px;
        padding: 4px 0;
      }
      .ant-input {
        margin-left: 15px;
        width: 102px;
        height: 28px;
        background: #ffffff;
        border-radius: 26px;
        font-size: 14px;
        font-weight: 400;
        color: @primary-color;
        line-height: 20px;
        padding: 4px 0;
        text-align: center;
      }

      .edit-text {
        cursor: pointer;
        margin-left: 23px;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
        line-height: 20px;
        text-decoration: underline;
      }
    }

    .exit {
      cursor: pointer;
      position: absolute;
      top: 76px;
      right: 85px;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      line-height: 20px;
    }
  }

  .personal-container {
    display: flex;
    flex-direction: row;
    // height: calc(100% - 172px);
    height: 717px;
    .personal-left {
      width: 208px;
      height: 100%;
      overflow: auto;
      background: #f5f5f5;

      /deep/ .ant-menu-sub.ant-menu-inline {
        background: #f5f5f5;
      }

      /deep/ .ant-menu-sub .ant-menu-item-selected {
        font-size: 14px;
        font-weight: 500;
        background: #dedede;
        color: #333333;
      }

      /deep/ .ant-menu .ant-menu-item-selected {
        background: #dedede;
        font-size: 14px;
        font-weight: 500;
        color: #333333;
      }
    }

    .personal-right {
      // height: 100%;
      flex: 1;
      padding: 0 0 24px 24px;
      background: #ffffff;
      overflow: auto;
      // box-shadow: 0px 2px 5px 2px rgba(202, 221, 247, 0.8);
      .spin-contain {
        min-height: 600px;
        // background-color: #e6f7ff;
        display: flex;
        align-items: center;
        justify-content: space-around;
      }
    }
    .box_shadow {
      padding: 0;
      margin: 0 0 24px 24px;
      box-shadow: 0px 2px 5px 2px rgba(202, 221, 247, 0.8);
    }
  }
}
.certification_btn {
  position: absolute;
  top: 50%;
  right: 40px;
  transform: translateY(-50%);
  padding: 0 15px;
  height: 38px;
  line-height: 34px;
  background: #b3b3b3;
  border-radius: 20px;
  border: 2px solid #ffffff;
  color: #fff;
  cursor: pointer;
}
.active_certification_btn {
  background: #f0b160;
}
.menu_icon {
  width: 16px;
  margin-right: 14px;
  vertical-align: text-bottom;
}
// ::v-deep .ant-menu {
//   .ant-menu-item,
//   .ant-menu-submenu-title {
//     height: 40px !important;
//     line-height: 40px !important;
//     span {
//       font-size: 14px !important;
//     }
//   }
// }
</style>
